<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {MagnifyingGlassIcon} from '@heroicons/vue/24/solid'

import UserName from './UserName.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const props = defineProps({

});

const emit = defineEmits([
    'selecteduser',
]);

const extra_data = reactive({
    loading: false,
    partial_username: "",
    users: [],
    show_input: false,
});

var timeout_id = null;

async function find_user(partial_username){
    clearTimeout(timeout_id);

    if(partial_username.length <= 2){
        return;
    }
    
    timeout_id = setTimeout(async () => {
        extra_data.loading = true;

        var url = app_server + `/user/find/?name=${partial_username}`;
        const response = await fetch(url, {
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        })    ;

        const data = await response.json();

        extra_data.users.length = 0;
        extra_data.users.push(...data.users);
        extra_data.loading = false;
    }, 800);
}

function select_user(user){
    extra_data.show_input = false;
    emit('selecteduser', user);
}

onMounted(async () => {
});

</script>
<template>
    <div class="bg-main-2-color relative z-50 size-6 text-base">
        <div class="flex items-center gap-x-2 right-0 absolute bg-main-2-color">
            <input v-model.trim="extra_data.partial_username" 
                @input="find_user(extra_data.partial_username)"
                class="h-10"
                placeholder="Username..."
                v-show="extra_data.show_input"
            />
            <div class="flex flex-col absolute top-10 right-0 py-3 px-2 rounded-b 
                 min-h-12 w-full gap-y-2 bg-main-3-color"
                 v-show="extra_data.show_input"
            >
                <div v-if="extra_data.users.length == 0">No results...</div>
                <div v-for="user in extra_data.users"
                     @click="select_user(user)"
                     class="z-50 hover:bg-accent-2-color cursor-pointer bg-main-3-color "
                >
                    <UserName 
                        :user="user" 
                        :link_to_userview="false"
                    />
                </div>
            </div>
            <MagnifyingGlassIcon 
                class="size-6 btn btn-yellow !p-0.5"
                @click="extra_data.show_input ^= true"

            />
        </div>
    </div>
</template>

<style>
</style>


