<script setup>
/*
*/
import Board from './Board.vue'
import Header from './Header.vue'
import Message from './Message.vue'
import Report from './Report.vue'
import UserName from './UserName.vue'
import Tooltip from './Tooltip.vue'
import ButtonLoad from './ButtonLoad.vue'
import DailyPuzzleAdd from './DailyPuzzleAdd.vue'

import {BoardState} from '../assets/js/board.js'
import {StateMachine } from '../assets/js/statemachine.js'
import {nextTick, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {CpuChipIcon, CheckCircleIcon, CalendarIcon, XCircleIcon, PuzzlePieceIcon, ShareIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const translate_double_moves = {
    "EQ ND": "No Double",
    "EQ DT": "Double, Take",
    "EQ DP": "Double, Pass",
};

const state = reactive({
    currentState: null,
    board: new BoardState(),
    player_color: "W",
});

const extra_data = reactive({
    board_message : "",
    puzzle_info: {},
    solve_info: {},
    selected: null,
    arrows: [],
    double_solution: "ND",
    login_message: "",
    leaderboard: [],
    leaderboard_timeframe: "daily",
    leaderboard_date: Date.now(),
    
    user_rank: null,

    loading_answer: false,
    loading_puzzles : false
});

const quiz_data = reactive({
    quiz: null,
    current_puzzle: null,
    equity_lost : 0,
    time_left : 0,
    result : null,
    quiz_type: null,
});

const piprush_data = reactive({
    top: null,
    bottom: null,
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});

var puzzle_id = undefined;

onMounted(async () => {
    userStore.loadUser();
    const quiz_id = route.params.quiz_id;
    
    if(quiz_id == "rush"){
        extra_data.quiz_type = "rush";
    }else if(quiz_id == "pip"){
        extra_data.quiz_type = "pip";
    }else{
        await get_quiz(quiz_id);
    }

    get_leaderboard();
    await next_puzzle();
});

async function create_quiz(quiz_type){
    const quiz_user_id = quiz_data.quiz != null ? quiz_data.quiz.quiz_user_id : null;
    const request_data = {
        quiz_type: quiz_type,
        quiz_user_id: quiz_user_id, 
    };
    if(quiz_data.quiz && quiz_type == 'custom'){
        request_data.nrof_puzzles = quiz_data.quiz.max_nrof_puzzles;
    }

    const response = await fetch(app_server + `/quiz/create/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(request_data),
    });

    const data = await response.json();
    quiz_data.quiz = data.quiz;
    quiz_data.equity_lost = data.equity_lost;
    quiz_data.time_left = data.time_left;
    extra_data.arrows = [];
    extra_data.selected = null;
    
    set_clock();

    router.replace({name: "quiz", params:{quiz_id: data.quiz.quiz_id}});
    console.log("Quiz created:", data);
}

async function create_custom_quiz(){
    const quiz_data = {
        "nrof_puzzles": custom_quiz_data.nrof_puzzles,
        "tags": Object.keys(custom_quiz_data.tags),
    };
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "custom",
            quiz_user_id: userStore.info.user_id, 
            ...quiz_data,
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
async function get_quiz(quiz_id){
    const response = await fetch(app_server + `/quiz/${quiz_id}/`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    quiz_data.quiz = data.quiz;
    quiz_data.result = data.result;
    quiz_data.equity_lost = data.equity_lost;
    quiz_data.time_left = data.time_left;
    extra_data.quiz_type = data.quiz.quiz_type;

    extra_data.arrows = [];
    set_clock();
    
    if(quiz_data.quiz.quiz_type == "pip"){
        nextTick( () => {
            focus('piprush_top_input');
        });
    }
    
    console.log("Loaded quiz:", data);
}

async function get_new_puzzles(quiz_id){
    extra_data.loading_puzzles = true;
    const response = await fetch(app_server + `/quiz/${quiz_id}/puzzles/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "nrof_puzzles": quiz_data.quiz.puzzles.length + 1,
        }),
    });

    const data = await response.json();
    quiz_data.quiz.puzzles = data.puzzles;
    quiz_data.quiz.puzzles_info = data.puzzles_info;

    quiz_data.time_left = data.time_left;
    clock_data.clock.delay = quiz_data.time_left;
    extra_data.loading_puzzles = false;
}

async function next_puzzle(){
    if(!quiz_data.quiz){
        console.log("Quiz not loaded", quiz_data);
        return;
    }
    if(is_finished()){
        console.log("Quiz finished", quiz_data);
        finish();
        return;
    }       
    console.debug("Next puzzle");
    var next_puzzle_id = null;
    for(var puzzle_id of quiz_data.quiz.puzzles){
        if(puzzle_id in quiz_data.quiz.solves){
            continue;
        }
        next_puzzle_id = puzzle_id;    
        break;
    }
    if(next_puzzle_id == null){
        await get_new_puzzles(quiz_data.quiz.quiz_id);
        // Fix, bit ugly
        for(var puzzle_id of quiz_data.quiz.puzzles){
            if(puzzle_id in quiz_data.quiz.solves){
                continue;
            }
            next_puzzle_id = puzzle_id;    
        }
    }
    if(next_puzzle_id == null){
        return null;
    }
    await load_puzzle(next_puzzle_id);
}

async function get_puzzle_solve(puzzle_id, solution){
    if(extra_data.puzzle_info.puzzle_type == "checker" && extra_data.quiz_type != 'pip'){
        const board = new BoardState(solution);
        solution = board.toPositionString();
    }
    const solutions = {};
    solutions[puzzle_id] = solution;

    extra_data.loading_answer = true;
    
    const response = await fetch(app_server + `/quiz/${ quiz_data.quiz.quiz_id }/solve/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "solutions": solutions,
        }),
    });
    const solve_data = await response.json();
    quiz_data.quiz.solves = Object.assign(quiz_data.quiz.solves, solve_data.solves);

    extra_data.loading_answer = false;
    
    // Finish if the quiz is finished
    finish();
}

async function load_puzzle(puzzle_id){
    if(quiz_data.quiz.puzzles_info && (puzzle_id in quiz_data.quiz.puzzles_info)){
        extra_data.puzzle_info = quiz_data.quiz.puzzles_info[puzzle_id];
    }else{
        const puzzle_data = await get_puzzle_start(puzzle_id); 
        extra_data.puzzle_info = puzzle_data.puzzle;
    }
    
    state.board = new BoardState(extra_data.puzzle_info.position);
    if(extra_data.quiz_type == 'pip'){
        state.board.dice = [];
        state.board.game_state = "R";
        state.board.cube.owner = "N";
        state.board.cube.value = "0";
    }
    state.player_color = state.board.opponent[state.board.color];
    state.currentState = state.board.toPositionString();
}

async function get_puzzle_start(puzzle_id){
    const response = await fetch(app_server + `/puzzle/${ puzzle_id }/start/`, {
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        mode: "cors",
    });
    const puzzle_data = await response.json();
    return puzzle_data;
}

async function get_leaderboard(){
    const date = new Date(extra_data.leaderboard_date).toISOString();
    const response = await fetch(app_server + `/quiz/leaderboard/${extra_data.quiz_type}/?timeframe=${extra_data.leaderboard_timeframe}&date=${date}`, {
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    extra_data.user_rank = null;
    const data = await response.json();
    extra_data.leaderboard = data.leaderboard;
    for(let index in extra_data.leaderboard){
        const user = extra_data.leaderboard[index][1];
        if(user.user_id == userStore.info.user_id && index >= 5){
            extra_data.user_rank = extra_data.leaderboard[index];
        }
    }
    
    console.log("Leaderboard", data);
}

function navigate_leaderboard(direction) {
    if (direction === 'current') {
        extra_data.leaderboard_date = Date.now();
    } else if (direction === 'previous') {
        if (extra_data.leaderboard_timeframe === 'daily') {
            extra_data.leaderboard_date -= 60*60*24*1000; // One day
        } else {
            const date = new Date(extra_data.leaderboard_date);
            date.setMonth(date.getMonth() - 1);
            extra_data.leaderboard_date = date.getTime();
        }
    } else if (direction === 'next') {
        if (extra_data.leaderboard_timeframe === 'daily') {
            extra_data.leaderboard_date += 60*60*24*1000; // One day
        } else {
            const date = new Date(extra_data.leaderboard_date);
            date.setMonth(date.getMonth() + 1);
            extra_data.leaderboard_date = date.getTime();
        }
    }
    get_leaderboard();
}

function is_next_disabled() {
    const current_date = new Date();
    const leaderboard_date = new Date(extra_data.leaderboard_date);
    
    if (extra_data.leaderboard_timeframe === 'daily') {
        return new Date(leaderboard_date.toDateString()) >= new Date(current_date.toDateString());
    }
    return (leaderboard_date.getFullYear() > current_date.getFullYear()) ||
           (leaderboard_date.getFullYear() === current_date.getFullYear() && 
            leaderboard_date.getMonth() >= current_date.getMonth());
}

async function handleMove(positionString, action=null){
    if(is_finished()){
        return;
    }

    if(action == "flag"){
        console.log("Flagged!", quiz_data.time_left)
        quiz_data.time_left = 0;
        finish();
        return;
    }

    const state_machine = new StateMachine();
    state_machine.player_color = state.player_color;
    state_machine.roll_dice_callback = () => [];
    
    const position = new BoardState(positionString)
    var solution = null;
    var new_board = state_machine.next_state(position, action);
    
    if(extra_data.puzzle_info.puzzle_type == "double" && (!["ND", "DT", "DP"].includes(action))){
        if(new_board.game_state == "PR"){
            solution = "ND";
        }else if(new_board.game_state == "D"){
            state.player_color = new_board.opponent[new_board.color];
            state.currentState = new_board.toPositionString();
            return; 
        }else if(new_board.game_state == "A"){
            solution = "DT";
        }else if(new_board.game_state == "P"){
            solution = "DP";
        }else{
            solution = null;
        }
    }else{
        solution = new_board.toPositionString();
    }

    const solved = await get_puzzle_solve(extra_data.puzzle_info.puzzle_id, solution);
    next_puzzle();
}

function set_clock(){
    if(is_finished()){
        quiz_data.time_left = 0
    }
    var duration = quiz_data.quiz.duration;
    if(quiz_data.time_left != null){
        duration = quiz_data.time_left;
    }
    if(duration >= 0){
        clock_data.clock = {
            delay: duration,
            W: 0,
            B: 0,
        }
        clock_data.clock_config = {
            delay: duration,
            reserve: 0,
        }
    }
}

function select_move_played(){
    const played = extra_data.solve_info.alt_played
    if(played >= 0){
        const move_played = extra_data.puzzle_info.analysis.moves[played];
        select_alternative(move_played);
    }else{
        if(extra_data.puzzle_info.puzzle_type == "checker"){
            const solution_board = new BoardState(extra_data.solve_info.solution);
            solution_board.dice = [];
            solution_board.update();
            state.currentState = solution_board.toPositionString();
            state.player_color = solution_board.opponent[solution_board.color];
        }else if(extra_data.puzzle_info.puzzle_type == "double"){
            const solution_board = new BoardState(extra_data.puzzle_info.position);
            solution_board.dice = [];
            solution_board.update();
            state.currentState = solution_board.toPositionString();
            state.player_color = solution_board.opponent[solution_board.color];
        }

    }
}

function select_alternative(move){
    const solution_board = new BoardState(state.currentState);
    const color = solution_board.opponent[solution_board.color];

    solution_board.update();
    state.currentState = solution_board.toPositionString();
    extra_data.arrows = [];
    for(const arrow_ of move.move.data){
        const arrow = arrow_.slice();
        if(color == "W"){
            arrow[0] = 25 - arrow[0];
            arrow[1] = 25 - arrow[1];
        }
        extra_data.arrows.push({
            start: arrow[0],
            end: arrow[1],
        });
    }
}

function get_move(){
    if(extra_data.puzzle_info.puzzle_type == "double"){
        const double_solutions = {
            "ND" : "No Double",
            "DT" : "Double, Take",
            "DP" : "Double, Pass",
        };
        return double_solutions[extra_data.solve_info.solution];
    }else{
        if(extra_data.solve_info.solution && extra_data.puzzle_info.position){
            const bs = new BoardState( extra_data.puzzle_info.position);
            return bs.getMove(new BoardState(extra_data.solve_info.solution)).text;
        }   
    }
    return "No Move" 
}

async function retry(){
    get_leaderboard();
    await create_quiz(extra_data.quiz_type);
    await next_puzzle();
    clock_data.clock.delay = quiz_data.time_left;
    finish();
}

async function copy_to_clipboard(text){
    if(text == null){
        text = window.location.href;
    }

    console.log(text);
    if(window.isSecureContext){
        await navigator.clipboard.writeText(text);
        messageStore.alertUser("Share", "Puzzle URL has been copied to your clipboard.");
    }
}

async function analyse_puzzle(puzzle_id){
    if(! is_finished()){
        return;
    }
    extra_data.selected = puzzle_id; 
    console.log("Selected", puzzle_id);
    await load_puzzle(puzzle_id);
    const move_played = quiz_data.quiz.solves[puzzle_id].alt_played;
    // select_alternative(quiz_data.result[puzzle_id].puzzle.analysis.moves[move_played]);
    if(extra_data.quiz_type != 'pip'){
        select_alternative(quiz_data.result[puzzle_id].puzzle.analysis.moves[0]);
    }
}

function get_eq_lost(puzzle_id){
    if(! puzzle_id in quiz_data.quiz.solves){
        return -1;
    }
    const solve = quiz_data.quiz.solves[puzzle_id];
    if(!solve){
        return -1;
    }
    return Math.min(1, solve.eq_difference);
}

function get_error_class(puzzle_id){
    const eq_difference = get_eq_lost(puzzle_id);
    const classes = [] 
    var pivot_points = [0.08, 0.02, 0];

    if(extra_data.quiz_type == 'pip'){
        pivot_points = [0.5, 0.1, 0];
    }


    if(eq_difference > pivot_points[0]){
        classes.push("bg-red-500");
    }else if(eq_difference > pivot_points[1]){
        classes.push("bg-yellow-500");
    }else if(eq_difference >= 0){
        classes.push("bg-green-500");
    }else{
    }

    if(puzzle_id == extra_data.selected){
        classes.push("ring");
    }
    
    return classes;
}

function get_score(){
    var score = 0;
    for(var solve in quiz_data.quiz.solves){
        const s = quiz_data.quiz.solves[solve];
        if(s.eq_difference < 0.02){
            score += 1;
        }
    }
    return score;
}

function get_total_eq_lost(){
    var eq_lost = 0;
    for(var solve in quiz_data.quiz.solves){
        const s = quiz_data.quiz.solves[solve];
        eq_lost += s.eq_difference;
    }
    return eq_lost;
}

function is_finished(){
    if(quiz_data.quiz == null){
        console.debug("No quiz!");
        return false;
    }
    if(quiz_data.quiz.max_nrof_puzzles != null && 
       quiz_data.quiz.max_nrof_puzzles <= Object.keys(quiz_data.quiz.solves).length){
       return true;
    }
    if(quiz_data.time_left == null){
        return true;
    }
    if(quiz_data.quiz.max_eq_lost != null && get_total_eq_lost() >= quiz_data.quiz.max_eq_lost){
        return true;
    } 

    return quiz_data.time_left <= 0.01;
}

function finish(){
    if(!is_finished()){
        return;
    }
    extra_data.selected = null;
    get_quiz(quiz_data.quiz.quiz_id);
    quiz_data.time_left = 0;
    clock_data.clock.delay = 0;
    get_leaderboard();
    console.log("Wrapped up quiz");
}

function get_eq_bar_class(){
    const lost_eq = get_total_eq_lost();
    const max_lost_eq = 0.200;
    const percentage = (100*lost_eq / max_lost_eq).toFixed(0);
    var error_level = "bg-green-500";
    if(percentage < 20){
    }else if(percentage < 60){
        error_level = "bg-yellow-500";
    }else{
        error_level = "bg-red-500";
    }
    const eq_bar = document.getElementById("eq-bar");
    if(eq_bar){
        eq_bar.style.width = percentage + "%";
    }
    return [error_level];
}

async function submit_piprush_solution(){
    if(is_finished()){
        return;
    }

    console.log(state.player_color);

    const solution = {};
    if(state.player_color == "W"){
        solution.W = piprush_data.bottom;
        solution.B = piprush_data.top;
    }else{
        solution.W = piprush_data.top;
        solution.B = piprush_data.bottom;
    }
    
    const solved = await get_puzzle_solve(extra_data.puzzle_info.puzzle_id, solution);
    next_puzzle();

    piprush_data.top = null;
    piprush_data.bottom = null;
    focus('piprush_top_input');
}

function focus(element_id){
    const elem = document.getElementById(element_id);
    if(elem != null){
        elem.focus();
    }
}


</script>
<template>
<div class="game flex-col relative">
    <Header />
    <div class="flex flex-col md:flex-row md:relative">
        <div class="md:w-9/12 md:h-rest relative">
            <Board :positionString="state.currentState" 
                   :player_color="state.player_color" 
                   :clock_data="clock_data"
                   :arrows="extra_data.arrows"
                   :show_pip_numbers="true"
                   :show_pip_count="extra_data.quiz_type != 'pip'"
                   :board_message="extra_data.board_message"
                   :can_do_actions="!is_finished()"
                   :show_away_default="true"
                   @move-end="handleMove"
            >
            </Board>
        </div>
        <div class="h-rest flex flex-col gap-y-2 grow w-full md:w-auto"

        >
            <h1 v-if="extra_data.quiz_type == 'rush'" 
                class="text-4xl font-semibold mt-4 mx-2 md:mt-2">Puzzle Rush</h1>
            <h1 v-else-if="extra_data.quiz_type == 'pip'" 
                class="text-4xl font-semibold mt-4 mx-2 md:mt-2">Pip Rush</h1>
            <h1 v-else-if="extra_data.quiz_type == 'user'" 
                class="text-4xl font-semibold mt-4 mx-2 md:mt-2">User Quiz</h1>
            <h1 v-else 
                class="text-4xl font-semibold mt-4 mx-2 md:mt-2">Quiz</h1>

            <div class="py-4 px-4 mx-2 bg-main-2-color rounded-lg flex flex-col gap-y-4"
            >
                <span class="font-thin flex gap-x-1 w-full" v-if="quiz_data.quiz && quiz_data.quiz.tags">
                    <span class="rounded px-2 py-0.5 bg-accent-2-color text-sm"
                        v-for="tag in quiz_data.quiz.tags">
                        {{ tag }}
                    </span>
                </span>
                <p class="text-2xl" v-if="is_finished() && quiz_data.quiz">
                Your score: {{ get_score() }}
                </p>
                <table class="font-thin w-full" v-if="is_finished() && quiz_data.quiz">
                    <tbody>
                    <tr>
                        <td>Average rating:</td>
                        <td>{{ quiz_data.result.rating_avg.toFixed(0) }}</td>
                    </tr>
                    <tr>
                        <td>Max solved rating:</td>
                        <td>{{ quiz_data.result.rating_max.toFixed(0) }}</td>
                    </tr>
                    </tbody>
                </table>

                <span v-if="is_finished() || quiz_data.quiz == null">
                    <button class="btn btn-blue h-10" @click="retry()">
                        <span v-if="extra_data.quiz_type == 'rush'">Start Rush</span>
                        <span v-else-if="extra_data.quiz_type == 'pip'">Start Pip Rush</span>
                        <span v-else>Retry</span>
                    </button>
                </span>

                <div v-if="(is_finished() || quiz_data.quiz == null) && (['rush', 'pip'].includes(extra_data.quiz_type))" >
                    <div class="flex justify-between">
                        <h2 class="text-lg">
                            <span class="capitalize">{{ extra_data.leaderboard_timeframe }}</span> 
                            Leaderboard:
                        </h2>
                        <span class="flex gap-x-2">
                        <button class="btn btn-blue text-sm" 
                            :disabled="extra_data.leaderboard_timeframe == 'daily'" 
                            @click="extra_data.leaderboard_timeframe='daily'; get_leaderboard();"
                        >
                            Daily
                        </button>
                        <button class="btn btn-blue text-sm" 
                            :disabled="extra_data.leaderboard_timeframe == 'monthly'" 
                            @click="extra_data.leaderboard_timeframe='monthly'; get_leaderboard();"
                        >
                            Monthly
                        </button>
                        <!--
                        <button class="btn btn-blue text-sm" 
                            :disabled="extra_data.leaderboard_timeframe == 'all-time'" 
                            @click="extra_data.leaderboard_timeframe='all-time'; get_leaderboard();"
                        >
                            All-Time
                        </button>
                        -->
                        </span>
                    </div>
                    <div class="w-full flex flex-col gap-y-2 mt-4" 
                        v-if="['rush', 'pip'].includes(extra_data.quiz_type)"
                    >
                        <div class="font-thin" v-if="extra_data.leaderboard.length == 0">
                            No one made it into the leaderboard! Be the first.
                        </div>
                        <span class="w-full flex"
                            v-for="username_score, index in extra_data.leaderboard.slice(0,5)"
                            :class="{'font-semibold': username_score[1].user_id == userStore.info.user_id}"
                        >
                            <span class="w-8">#{{ username_score[0] }}</span>
                            <span class="grow">
                                <UserName :user="username_score[1]" />
                            </span>
                            <span class="w-8">{{ username_score[2] }}</span>
                        </span>
                        <span class="w-full flex font-semibold" v-if="extra_data.user_rank">
                            <span class="w-8">#{{ extra_data.user_rank[0] }}</span>
                            <span class="grow">
                                <UserName :user="extra_data.user_rank[1]" />
                            </span>
                            <span class="w-8">{{ extra_data.user_rank[2] }}</span>
            
                        </span>
                    </div>
                    <div class="flex justify-between mt-4">
                        <button class="btn btn-red text-sm"
                                @click="navigate_leaderboard('previous')"
                        >
                            Previous
                        </button>
                        <button class="btn btn-red text-sm"
                                @click="navigate_leaderboard('next')"
                                :disabled="is_next_disabled()"
                        >
                            Next
                        </button>
                        <button class="btn btn-red text-sm"
                                @click="navigate_leaderboard('current')"
                        >
                            Current
                        </button>
                    </div>
                </div>
                
            </div>
            <div  v-if="!is_finished() && quiz_data.quiz">
            <div v-if="quiz_data.quiz.quiz_type == 'rush'" 
                 class="w-9/12 h-4 mx-2 mb-4 relative border-2 rounded"> 
                <div id="eq-bar" class="h-full" :class="get_eq_bar_class()">
                </div>
            </div>
            <div v-else-if="quiz_data.quiz.quiz_type == 'pip'"></div>
            <div v-else class="text-center text-2xl font-semibold my-4">
                Puzzles: {{ Object.keys(quiz_data.quiz.solves).length }} / {{ quiz_data.quiz.puzzles.length }}
            </div>
            <div class="text-center text-2xl font-semibold">
                Score: {{ get_score() }}
            </div>
            </div>

            <div v-if="(!is_finished() && quiz_data.quiz && quiz_data.quiz.quiz_type == 'pip')"
                 class="px-2 flex flex-col gap-y-4 my-8"
            >
                <table class=" w-full">
                    <tbody>
                    <tr class="">
                        <td class="">
                            Top:
                        </td>
                        <td>
                            <input type="number" min="0" max="360" id="piprush_top_input"
                                   tabindex="1" autofocus
                                   v-model="piprush_data.top" placeholder="167"
                                   @keydown.enter="submit_piprush_solution()" 
                                   @keydown.tab.prevent="focus('piprush_bottom_input')"
                            >
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bottom:
                        </td>
                        <td>
                            <input type="number" min="0" max="360"  id="piprush_bottom_input"
                                tabindex="2"
                                v-model="piprush_data.bottom" placeholder="167"
                                @keydown.enter="submit_piprush_solution()" 
                               @keydown.tab.prevent="focus('piprush_top_input')"
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ButtonLoad 
                    class="btn btn-blue w-1/2 h-12"
                    :is_disabled="piprush_data.top == null || piprush_data.bottom == null"
                    :loading="extra_data.loading_answer || extra_data.loading_puzzles"
                    @click="submit_piprush_solution()"
                >
                    Submit Answer
                </ButtonLoad>
                
            </div>

            <div class="flex flex-col gap-2" 
                v-if="quiz_data.quiz">
            <div class="grid grid-rows-5 grid-flow-col px-2 gap-1 w-fit md:grid-rows-none md:grid-cols-5 md:w-full md:grid-flow-row">
                <div 
                    v-for="puzzle_id, index in quiz_data.quiz.puzzles.filter(x => x in quiz_data.quiz.solves)"
                    :class="[get_error_class(puzzle_id)]"
                    class="cursor-pointer aspect-square h-8 md:h-auto flex items-center justify-center hover:text-dark-color text-transparent"
                    @click="analyse_puzzle(puzzle_id)"
                >
                    <span v-if="is_finished()" 
                          class="text-base text-center align-middle inline-block mix-blend-luminosity
                                 numeric-tabular inline-block"
                    >
                        {{ (1000*get_eq_lost(puzzle_id)).toFixed(0) }}
                    </span>
                </div>
            </div>

            <div class="w-full px-2 my-8"
                v-if="is_finished() && quiz_data.result && extra_data.selected && extra_data.quiz_type == 'pip'"
                :set="solve=quiz_data.quiz.solves[extra_data.selected]"
            >
                <table class="">
                    <tbody>
                    <tr>
                        <td></td>
                        <th class="text-center px-8">Answer</th>
                        <th class="text-center px-8">Your</th>
                    </tr>
                    <tr>
                        <td>Top</td>
                        <td class="text-center">{{ solve.solution[0] }}</td>
                        <td class="text-center"
                            :class="{'text-blunder-color': solve.alt_played[state.board.opponent[state.player_color]] != solve.solution[0]}"
                        >
                            {{ solve.alt_played[state.board.opponent[state.player_color]] }}</td>
                    </tr>
                    <tr>
                        <td>Bottom</td>
                        <td class="text-center">{{ solve.solution[1] }}</td>
                        <td class="text-center"
                            :class="{'text-blunder-color': solve.alt_played[state.player_color] != solve.solution[1]}"
                        >
                            {{ solve.alt_played[state.player_color] }}
                        </td>
                    </tr>
                    </tbody>
                </table>
                
            </div>
            <div class="w-full px-2" 
                v-else-if="is_finished() && quiz_data.result && extra_data.selected"
            >
                <h1 class="text-xl mt-4 flex justify-between">
                <span>Analysis</span>
                <div class="flex flex items-center gap-x-2">
                    <DailyPuzzleAdd :puzzle_id="extra_data.selected" />
                    <router-link
                        :to="{name: 'position', params:{position_id: state.board.toPositionString()}}">
                        <CpuChipIcon class="w-6 h-6 inline-block text-accent-3-color hover:text-accent-2-color cursor-pointer" />
                    </router-link>
                </div>
                </h1>
                <div v-if="extra_data.puzzle_info.puzzle_type == 'double'"
                     class="my-4"
                    >
                    <div
                        v-for="move, i in quiz_data.result[extra_data.selected].puzzle.analysis.double['cube recommendation'].actions"
                        :set="best_move = quiz_data.result[extra_data.selected].puzzle.analysis.double['cube recommendation'].best"
                    >
                    <div
                        class="flex justify-between gap-y-1" 
                        :class="{'font-semibold': move[0] == 'EQ '+quiz_data.quiz.solves[extra_data.selected].solution}"
                    >
                        <span>{{ translate_double_moves[move[0]] }}</span>
                        <span class="tabular-nums">
                            {{ move[1].toFixed(3) }} ({{ (move[2]).toFixed(3).padStart(6) }})
                        </span>
                    </div>
                    </div>
                </div>
                <table v-else class="w-full">
                    <tbody>
                    <tr v-for="move in quiz_data.result[extra_data.selected].puzzle.analysis.moves.slice(0,5)"
                    >
                        <td
                            :class="{'font-semibold': quiz_data.quiz.solves[extra_data.selected].alt_played == move.alt}"
                            >
                            {{ move.move.repr }}                        
                        </td>
                        <td class="pl-4 text-right">{{ move.EQ.toFixed(3) }}</td>
                    </tr>
                    </tbody>
                </table>
                <h1 class="text-xl font-thin mt-4">Puzzle stats</h1>
                 
                <table class="font-thin w-full">
                    <tbody>
                    <tr>
                        <td>Rating:</td>
                        <td>{{ quiz_data.result[extra_data.selected].rating.toFixed(0) }}</td>
                    </tr>
                    <tr>
                        <td>Solves:</td>
                        <td>{{ quiz_data.result[extra_data.selected].puzzle.solves }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
    /*background-color: var(--stone-w-color);*/
}
</style>
