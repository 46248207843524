<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var analyser_id;

const extra_data = reactive({
    action: "remove", 
    data: {
        duration: 5,
        next_maintenance: null,
    },
});

const maintenance_data = reactive({
    data: null,
});

onMounted(() => {
    get_maintenance();
});

async function change_maintenance(){
    const req_data = Object.assign({}, extra_data);
    req_data.data = Object.assign({}, extra_data.data);
    
    req_data.data.duration = req_data.data.duration * 60;
    req_data.data.next_maintenance = Math.floor(
        new Date(req_data.data.next_maintenance).valueOf() / 1000
    );
    console.log(req_data);

    const response = await fetch("/app/admin/maintenance/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(req_data),
    });
    
    const data = await response.json();
    console.log(data);

    get_maintenance();
}

async function get_maintenance(){
    var url = app_server + `/maintenance/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    maintenance_data.duration = data.duration;
    maintenance_data.next_maintenance = data.next_maintenance;
}

</script>

<template>
    <button class="btn btn-blue" @click="extra_data.action='remove'; change_maintenance()">
        Stop maintenance mode
    </button>
    <div class="m-8 flex flex-col gap-y-3 w-64" v-if="maintenance_data.duration != null">
        <h2 class="text-xl">Current Maintenance</h2>
        Starts on {{ new Date(maintenance_data.next_maintenance *1000).toDateString() }} 
        <b>{{ new Date(maintenance_data.next_maintenance *1000).toTimeString() }}</b>
        for 
        <b>{{ maintenance_data.duration / 60 }}</b> minutes
    </div>
    <div class="m-8 flex flex-col gap-y-3 w-64">
        <h2 class="text-xl">Start Maintenance Mode</h2>
        <input v-model="extra_data.data.duration" 
               placeholder="Time in minutes"
        />
        <input type="datetime-local" 
               v-model="extra_data.data.next_maintenance" 
               placeholder="Time in minutes"
        />
        <button class="btn btn-blue h-10" 
            @click="extra_data.action='add';change_maintenance()">
            Start Maintenance
        </button>
    </div>
</template>

<style>
</style>


