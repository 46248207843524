<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Message from './Message.vue'
import UserName from './UserName.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const actions = [
    "chat", "match", "login",
];

const durations = [
    ["1h", 3600],
    ["1d", 3600*24],
    ["3d", 3600*24*3],
    ["1w", 3600*24*7],
    ["1m", 3600*24*31],
    ["1y", 3600*24*365],
    ["1c", 3600*24*365*100],
];

const duration_lookup = Object.fromEntries(durations.map( ([a,b]) => [b,a]));
console.log(duration_lookup);

const extra_data = reactive({
    report: null,
    extra_data: null,
    reports: [],
    user: null,
    user_submitted: null,
    open_modal: false,
});

const timeout_data = reactive({
    reason: "",
    duration: 0,
    obj: null,
    user_id: "",
});

function go_to_report(report){ // COPIED from ModerateReports.vue
    if(report.object_type == "user"){
        router.push({name: 'mod-report-user', params: {report_id: report.report_id}});
    }else if(report.object_type == "match"){
        router.push();
    }else if(report.object_type == "puzzle"){
        router.push();
    }
}

watch( () => route.params.report_id, (newID, oldID) =>{
    get_report();
});


async function get_report(){
    const report_id = route.params.report_id;

    const response = await fetch(app_server + `/report/${report_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    console.log(data);

    extra_data.report = data.report;
    extra_data.extra_data = data.extra_data;
    extra_data.reports = data.reports;
    extra_data.user_id = data.report.object_id;

    get_user_info().then(x => extra_data.user = x);
    get_user_info(extra_data.report.submitter_id).then(x => extra_data.user_submitted = x);
}

async function get_user_info(user_id=null){
    if(user_id == null){
        user_id = extra_data.user_id;
    }

    const response = await fetch(app_server + `/user/${user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    
    const user = data.user; 
    user.date_joined = new Date(user.date_joined);

    console.log(data);

    return user
}

async function get_user_matches(options){
    const url = `/user/${extra_data.user_id}/matches/?${new URLSearchParams(options).toString()}`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_matches = await response.json();
    
    for(let match of user_matches.matches){
        match.score = JSON.parse(match.score);
    }
    
    return user_matches.matches;
}

async function submit_timeout(){
    timeout_data.user_id = extra_data.user.user_id;

    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/restriction/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(timeout_data),
    });
    
    const data = await response.json();
    console.log("Restriction", data);
    extra_data.open_modal = false;
    get_report();
    messageStore.alertUser("Added Timeout", "Succesfully added a timeout");
}

async function nullify_timeouts(){
    timeout_data.user_id = extra_data.user.user_id;

    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/restriction/`, {
        method: "DELETE",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log("Restriction", data);
    extra_data.open_modal = false;
    get_report();
    messageStore.alertUser("Nullified Timeout", "Succesfully cancelled the timeouts for this report.");
}

async function change_report_status(new_status){
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/changestatus/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({'status': new_status}),
    });
    
    const data = await response.json();
    console.log("Changed status", data);
    get_report();
    messageStore.alertUser("Changed Status", "Succesfully changed the status to: "+new_status);
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        get_report(),
    ])
});

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-8 mb-32">
        <h1 class="text-4xl mt-8 mb-4 font-semibold">User Report</h1>
        <div class="flex gap-x-4 w-full"
             v-if="extra_data.user != null"
        >
            <div class="flex flex-col gap-y-4"
            >
                <h3 class="text-lg font-medium">
                    <router-link :to="{name: 'user-info', params:{user_id: extra_data.user.user_id}}">
                        <UserName :user="extra_data.user" />
                    </router-link>
                </h3>
                <div class="flex gap-x-4">
                    <span class="flex items-center">
                        <FireIcon class="size-6" /> 
                        {{ extra_data.user.rating.toFixed(0) }}
                    </span>
                    <span class="flex items-center">
                        XP {{extra_data.user.experience.toFixed(0)}}
                    </span>
                </div>
            </div>

            <div class="w-16 h-16"
                 :class="{'border-case-med-color border flex justify-center items-center': !extra_data.user.avatar}">
                <Avatar :avatar_id="extra_data.user.avatar"/>
                <span v-if="!extra_data.user.avatar">
                    Avatar
                </span>
            </div>
        </div>
        <div v-if="extra_data.report != null">
            <h2 class="text-2xl">Report</h2>
            <h3 class="text-xl">Status: {{ extra_data.report.status }}</h3>
            <h3 class="text-xl">Reason: {{ extra_data.report.reason }}</h3>
            <h3 class="font-light">Time: {{ (new Date(extra_data.report.submit_time*1000)).toISOString().slice(0,19) }}</h3>
            <h3 class="font-light flex items-center" 
                v-if="extra_data.user_submitted != null"
            >
                <span>Submitted By: </span>
                <router-link :to="{name: 'user-info', params:{user_id: extra_data.user_submitted.user_id}}">
                    <UserName :user="extra_data.user_submitted"/>
                </router-link>
            </h3>
            <div class="my-4">
                {{ extra_data.report.description }}
            </div>
            <button class="btn btn-blue mt-8 h-10"
                    @click="change_report_status('closed')"
            >
                Close Report
            </button>
        </div>
        <div v-if="extra_data.report != null && extra_data.report.status == 'open'" class="flex flex-col gap-y-4">
            <h2 class="text-2xl">Timeout</h2>
            <div v-for="action in actions" class="">
                <div class="text-xl">{{ action }}</div>
                <div class="flex gap-x-2">
                <button v-for="duration in durations" 
                    class="btn btn-blue w-16"
                    @click="timeout_data.duration = duration[1]; timeout_data.obj=action; extra_data.open_modal=true;"
                >
                    {{ duration[0] }}
                </button>
                </div>
            </div>
        </div>
        <div v-if="extra_data.extra_data != null">
            <h2 class="text-2xl">Previous Timeouts</h2>
            <div class="flex justify-between gap-x-8"
                 v-for="timeout in extra_data.extra_data.restrictions"
                 :class="{'font-semibold': timeout.time_until*1000 > Date.now()}"
            >
                <span class="grow">
                    {{ timeout.obj || 'No Reason'}}
                </span> 
                <span class="">
                    {{ timeout.enforced_by.slice(0,5) }}
                </span> 
                <span>
                    {{ duration_lookup[timeout.duration] }}
                </span> 
                <span class="font-light tabular-nums">
                    {{ (new Date(timeout.time_until*1000)).toISOString().slice(0,19) }}
                </span>
            </div>
            <div class="mt-8">
                <button class="btn btn-red" @click="nullify_timeouts()">
                    Nullify Timouts
                </button>
            </div>
        </div>

        <div>
            <h2 class="text-2xl">Recent Reports</h2>
            <div class="flex justify-between gap-x-8"
                 v-for="report in extra_data.reports"
            >
                <span class="grow hover:underline hover:text-field-color cursor-pointer" 
                    @click="go_to_report(report)"
                >
                    {{ report.reason || 'No Reason'}}
                </span> 
                <span>
                    {{ report.status}}
                </span> 
                <span>
                    {{ report.submitter_id.slice(0,5)}}
                </span> 
                <span class="font-light tabular-nums">
                    {{ (new Date(report.submit_time*1000)).toISOString().slice(0,19) }}
                </span>
            </div>
        </div>

        <div v-if="extra_data.extra_data != null && extra_data.user != null && extra_data.user_submitted != null">
            <h2 class="text-2xl">Chat History</h2>
            <div v-for="chat in extra_data.extra_data.chats"
                 class="flex flex-col gap-y-1 border-b-2"
            >
                <div v-for="message in chat.messages.filter( (x) => x.sender != null )"
                     class="flex gap-x-4"
                >
                    <span class="font-light tabular-nums">
                        {{ (new Date(message.timestamp*1000)).toISOString().slice(0,19) }}
                    </span>
                    <span v-if="message.sender == extra_data.user.user_id"> 
                        {{ extra_data.user.username }}
                    </span>
                    <span v-else-if="message.sender == extra_data.user_submitted.user_id"> 
                        {{ extra_data.user_submitted.username }}
                    </span>
                    <span v-else>
                        {{ message.sender.slice(0,5) }}
                    </span>
                    <span class="grow" 
                        :class="{'font-semibold': extra_data.user.user_id == message.sender}">
                        {{ message.message }}
                    </span>

                </div>
            </div>
        </div>
    </div>
        <Teleport to="body">
        <TransitionRoot as="template" :show="extra_data.open_modal">
        <Dialog as="div" class="relative z-10" @close="extra_data.open_modal = false">
          <TransitionChild as="template" 
            enter="ease-out duration-300" 
            enter-from="opacity-0" enter-to="opacity-100" 
            leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">

            <div class="flex flex-col gap-y-8">
            <h1 class="text-xl font-semibold pt-2 flex gap-x-4 mb-8">
                <span> Give a Timeout to </span>
                <UserName :user="extra_data.user"/>
            </h1>
            <h2 class="text-xl"> 
                The user won't be able to <b>{{ timeout_data.obj }}</b> for 
                <b>{{ timeout_data.duration / 3600 }}</b> hours
            </h2>

            <label class="flex flex-col">
                Reason (not shown to user)
            <textarea v-model="timeout_data.reason"></textarea>
            </label>

            <label class="font-light text-sm">
                <input type="checkbox" v-model="timeout_data.understood"/>
                I understand that the timeout is irrevocable and it is my duty 
                to thoroughly asses the situation.
            </label>

            <div>
                <button class="btn btn-blue mt-2 w-full" @click="submit_timeout()"
                    :disabled="!timeout_data.understood || timeout_data.reason.length == 0">
                    Apply Timeout 
                </button>
                <button class="btn btn-red mt-2 w-full" @click="extra_data.open_modal=false">
                    Cancel
                </button>
            </div>
            </div>
            
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
        </Teleport>
</template>

<style>
</style>
