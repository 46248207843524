<script setup>

import {nextTick, watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {SpeakerXMarkIcon, MegaphoneIcon, ChevronLeftIcon, ChatBubbleLeftIcon, ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon, UserPlusIcon, PlusIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Tooltip from './Tooltip.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();


const extra_data = reactive({
    minimized: true,
    sending: false,
    show_start_chat: false,
    start_chat_with: "",
    join_lobby_name: "",
    active_lobby: null,
    sidebar: "collapsed",
});

const props = defineProps({
    lobbies : {
        type: Array,
        default: [],
    },
    muted : {
        type: Boolean,
        default: false,
    },
    toggle : {
        type: Boolean,
        default: null,
    },
    flex : {
        type: Boolean,
        default: null,
    },
});

onMounted(() => {
    userStore.loadUser().then( async () => {;
        await sseStore.connect();
        await chatStore.connect();
        scroll_chat_to_bottom();
    });
});

function scroll_chat_to_bottom(){
    nextTick(() => {
        const element = document.getElementById("chat-messages");
        if(element != null && !extra_data.minimized){
            element.scrollTop = element.scrollHeight;
            chatStore.set_read(extra_data.active_lobby);
        }else{
        }
    });
}

chatStore.$subscribe( (mutation, state) => {
    scroll_chat_to_bottom();
    if(extra_data.active_lobby == null && Object.keys(chatStore.chats.length > 0)){
        extra_data.active_lobby = Object.keys(chatStore.chats)[0];
    }
});

watch(() => props.toggle, async (newToggle, toggle) => {
    if(newToggle != null){
        extra_data.minimized = !extra_data.minimized;
        
        nextTick( () => {
            window.dispatchEvent(new Event('resize'));
            document.getElementById("chat-input").focus();
        }
        ); // We dispatch the resize event to trigger redrawing of the board
    }
});
watch(() => extra_data.minimized, async (newToggle, toggle) => {
    if(extra_data.minimized){
        return;
    }
    nextTick( () => {
        window.dispatchEvent(new Event('resize'));
        document.getElementById("chat-input").focus();
    }); // We dispatch the resize event to trigger redrawing of the board
});
watch( () => props.lobbies, (newLobbies, lobbies) =>{
    if(!newLobbies){
        return;
    }
    for(const chat_key of newLobbies){
        chatStore.get_chat(chat_key);
        extra_data.active_lobby = chat_key;
    }
});

watch(extra_data, () => {
    scroll_chat_to_bottom();
});

async function start_chat(){
    const active_chat = await chatStore.start_chat(extra_data.start_chat_with); 
    if(active_chat == null){
        return; 
    }
    extra_data.active_lobby = active_chat.lobby;
    extra_data.start_chat_with='';
    scroll_chat_to_bottom();
    extra_data.show_start_chat = false;
}

async function leave_chat(){
    const success = await chatStore.leave_chat(extra_data.active_lobby); 
    
}

async function join_lobby(lobby_name){
    const active_chat = await chatStore.join_lobby(lobby_name)
    if(active_chat == null){
        return; 
    }
    extra_data.active_lobby = active_chat.lobby;
    scroll_chat_to_bottom();

    extra_data.start_chat_with='';
    extra_data.show_start_chat = false;
}

function get_active_chat(){
    if(extra_data.active_lobby == null){
        return null;
    }
    // console.log("ACTIVE CHAT", extra_data.active_lobby, chatStore.chats[extra_data.active_lobby]);
    return chatStore.chats[extra_data.active_lobby];
}

function send_chat(){
    chatStore.send_chat(extra_data.active_lobby, extra_data.current_message);
    extra_data.current_message = "";
}

function get_user(message){
    const active_chat = get_active_chat()
    if(message.sender == null || active_chat == null){
        return null;
    }

    if(message.sender == userStore.info.user_id){
        return {
            username: "You",
            online: true,
            last_seen: Date.now()/1000,
        };
    }

    return active_chat.others[message.sender];
}

function get_time(seconds){
    /*
    * returns a human reabable time string (bit overcomplicated, but wanted it to
    * be nicely formatted.
    */
    const months = Math.floor(seconds / (30*24*60*60)).toString();
    const days = Math.floor((seconds % (30*24*60*60)) / (24*60*60)).toString();
    const hours = Math.floor((seconds % (24*60*60)) / (60*60)).toString();
    const minutes = Math.floor((seconds % (60*60)) / 60).toString();
    const sec = Math.floor(seconds % 60).toString();

    var time = 0;
    var unit = ""

    if(months > 0){
        time = months;
        unit = `months`;
    }else if(days > 0){
        time = days;
        unit = `days`;
    }else if(hours > 0){
        time = hours;
        unit = `hours`;
    }else if(seconds >= 60){
        time = minutes;
        unit = `minutes`
    }else{
        time = sec; 
        unit = "seconds";
    }

    if(time == 1){
        unit = unit.slice(0, -1);
    }
    
    return `${time} ${unit}`;
}

</script>
<template>
<slot>
    <div>
        <div class="relative m-4">
            <ChatBubbleLeftIcon 
                class="w-8 h-8 hover:text-field-color text-field-med-color cursor-pointer"
                :class="{'grayscale': chatStore.timeout}"
                @click="extra_data.minimized = false"
            />
            <div v-if="chatStore.unread > 0" 
                  class="inline-flex justify-center items-center rounded-full w-5 h-5 bg-stone-b-color absolute -top-2 -right-2 z-20 text-case-light-color text-xs font-semibold">
                {{ chatStore.unread }}
            </div>
        
        </div>
    </div>
</slot>

<div v-show="!extra_data.minimized" 
    class="flex fixed inset-0 text-case-light-color rounded-t-lg z-20 max-h-screen"
    :class="{'md:static md:inset-auto md:relative': props.flex, 
             'md:inset-auto md:bottom-0 md:right-0 md:h-[36rem] md:w-fit': !props.flex}"
    @keydown.esc="extra_data.minimized = true"
>
    <div class="bg-case-med-color opacity-80 inset-0 absolute rounded-t-lg"></div>

    <div v-if="extra_data.sidebar == 'collapsed'"
         class="flex flex-col gap-y-4 py-4 px-2 backdrop-brightness-50"
    >
        <Tooltip :text="'Recent Chats'"
            class="relative size-6 block"
        >
            <ChatBubbleLeftRightIcon 
                class="size-6 cursor-pointer hover:text-field-med-color"
                @click="extra_data.sidebar='chats'"
                                    
            />
            <span v-if="chatStore.unread_chats > 0" 
                class="size-4 px-1 rounded-full bg-field-color text-text-color text-xs text-center
                      absolute -top-1 -right-1"
            >
                {{ chatStore.unread_chats }}
            </span>
        </Tooltip>
        <Tooltip :text="'Your Lobbies'"
            class="relative size-6 block"
        >
            <MegaphoneIcon 
                class="size-6 cursor-pointer hover:text-field-med-color"
                @click="extra_data.sidebar='lobbies'"
            />
            <span v-if="chatStore.unread_lobbies > 0" 
                class="size-4 px-1 rounded-full bg-field-color text-text-color text-xs text-center
                      absolute -top-1 -right-1"
            >
                {{ chatStore.unread_lobbies }}
            </span>
        </Tooltip>
    </div>
    <div v-else-if="extra_data.sidebar == 'chats'" 
        class="flex flex-col relative z-10 py-4 gap-y-2 backdrop-brightness-50 rounded-tl-lg"
    >
        <div class="flex justify-between items-center gap-x-4 border-b-2 px-2">
            <Tooltip :text="'Collapse'">
                <ChevronLeftIcon 
                    class="size-4 cursor-pointer hover:text-field-med-color"
                    @click="extra_data.sidebar='collapsed'"
                                        
                />
            </Tooltip>
            <div class="mb-2 font-bold align-middle">Recent</div>
            <UserPlusIcon
                class="w-6 h-6 hover:text-field-med-color text-field-light-color cursor-pointer"
                @click="extra_data.show_start_chat = !extra_data.show_start_chat"
            />
        </div>
        <div class="relative flex px-2" v-show="extra_data.show_start_chat">
            <input type="input" v-model="extra_data.start_chat_with" 
                class="w-full border-b-4 border-case-med-color bg-inherit h-8 placeholder:text-case-light-color placeholder:italic"
                placeholder="Username..."
                @keydown.enter.exact.stop.prevent="start_chat()"
            />
            <PlusIcon 
                class="w-6 h-6 absolute right-2 hover:text-field-med-color text-field-light-color cursor-pointer"
                :disabled="extra_data.start_chat_with.length == 0"
                @click="start_chat()"
            />
        </div>
        <div v-for="chat in chatStore.sorted_chats" 
             @click="extra_data.active_lobby = chat.lobby"
             :class="{'font-bold': extra_data.active_lobby == chat.lobby}"
             class="cursor-pointer px-2 hover:font-semibold transition-all flex justify-between items-center relative"
        >
            <span v-if="chat.name" class="z-20">
                <div class="inline-block w-2 h-2 rounded-full" 
                    :class="{
                        'bg-field-med-color': Object.values(chat.others).filter( x => x.online)
                    }" 
                />
                {{ chat.name }}
            </span>
            <span v-if="chat.unread != null && chat.unread > 0" 
                class="h-4 px-1 mr-2 rounded-full border text-xs text-center">
                {{ chat.unread }}
            </span>
            <div v-if="extra_data.active_lobby == chat.lobby" 
                class="size-full absolute -inset-y-1 inset-x-0 bg-gradient-to-r from-field-color opacity-30 z-10" />
        </div>
        <div v-if="chatStore.active_chats.length > 0" class="flex justify-center">
            <button @click="chatStore.load_active_chats()" class="btn btn-blue">
                Load More
            </button> 
        </div>
    </div>
    <div v-else-if="extra_data.sidebar == 'lobbies'" 
        class="flex flex-col relative z-10 py-4 gap-y-2 backdrop-brightness-50 rounded-tl-lg"
    >
        <div class="flex justify-between items-center gap-x-4 border-b-2 px-2">
            <Tooltip :text="'Collapse'">
                <ChevronLeftIcon 
                    class="size-4 cursor-pointer hover:text-field-med-color"
                    @click="extra_data.sidebar='collapsed'"
                                        
                />
            </Tooltip>
            <div class="mb-2 font-bold align-middle">Lobbies</div>
        </div>
        <div v-for="chat in chatStore.sorted_lobbies" 
             @click="extra_data.active_lobby = chat.lobby"
             :class="{'font-bold': extra_data.active_lobby == chat.lobby}"
             class="cursor-pointer px-2 hover:font-semibold transition-all flex justify-between items-center relative"
        >
            <span v-if="chat.name" class="z-20">
                <div class="inline-block w-2 h-2 rounded-full" 
                    :class="{
                        'bg-field-med-color': Object.values(chat.others).filter( x => x.online)
                    }" 
                />
                {{ chat.name }}
            </span>
            <span v-if="chat.unread != null && chat.unread > 0" class="h-4 px-1 mr-2 rounded-full border text-xs text-center">{{ chat.unread }}</span>
            <div v-if="extra_data.active_lobby == chat.lobby" class="size-full absolute -inset-y-1 inset-x-0 bg-gradient-to-r from-field-color opacity-30 z-10" />
        </div>
        <div v-if="chatStore.active_chats.length > 0" class="flex justify-center">
            <button @click="chatStore.load_active_chats()" class="btn btn-blue">
                Load More
            </button> 
        </div>
        <span class="underline text-xl font-semibold mt-8 pl-2"> Featured </span>
        <div v-for="lobby in chatStore.featured_lobbies"
             @click="join_lobby(lobby)"
             class="cursor-pointer pl-2 hover:font-semibold transition-all flex justify-between items-center relative"
        >
            <Tooltip text="Join the lobby">
                {{ lobby }}
            </Tooltip>
        </div>
    </div>

    <div class="flex flex-col  min-w-0 justify-start h-full z-10 grow rounded-tl-lg">

        <div class="flex justify-between items-center">
            <h1 class="p-4 pt-2 text-xl font-semibold"> 
                <span v-if="chatStore.timeout">
                    Chat Timeout
                </span>
                <span class="flex gap-x-2 items-center group"
                    v-else-if="get_active_chat() != null"
                >
                    {{ chatStore.get_lobby_name(get_active_chat()) }}
                    
                    <Tooltip text="Leave this chat.">
                    <SpeakerXMarkIcon class="size-6 group-hover:opacity-100 opacity-10 
                                      transition delay-500 cursor-pointer" 
                                      @click="leave_chat()"
                    />
                    </Tooltip>
                </span>
                <span v-else>
                    No active chat.
                </span>
            </h1>
            <XMarkIcon class="w-6 h-6 mr-8 my-2 cursor-pointer hover:text-field-color" 
                @click="extra_data.minimized = true"
            />
        </div>
        
        <div v-if="chatStore.timeout"
             class="px-4 py-4 flex flex-col box-border grow justify-start overflow-y-scroll overflow-x-hidden gap-y-1 md:w-96"
        >
            You received a chat timeout due to not adhering to the community guidelines.
            If this is your first offense the timeout will most probably only last for
            a short while. In the future try to behave better and we won't have to
            give you a timeout.
        </div>
        <div class="px-4 py-4 flex flex-col box-border grow justify-start overflow-y-scroll overflow-x-hidden gap-y-1 md:w-96"
            id="chat-messages"
            v-else-if="get_active_chat() != null"

             @click="extra_data.minimized=true"
        >
            <div v-for="message in get_active_chat().messages"
                :class="{
                     'self-start mr-8 pl-2 pr-4 rounded-e-xl rounded-ss-xl bg-field-dark-color': message.sender != null && message.sender != userStore.info.user_id, 
                     'self-end ml-8 pr-2 pl-4 rounded-s-xl rounded-se-xl  bg-field-med-color': message.sender == userStore.info.user_id,
                     'self-center border-t-2 border-case-light-color pt-1 mt-2 w-full text-center':  message.sender == null,
                }"
                class="py-2"
            >
                <div class="flex flex-col gap-y-1">
                    <div v-if="get_user(message) != null"
                         class="text-sm font-bold flex justify-between gap-x-8 items-center"
                        :set="user=get_user(message)"
                    >
                        <span>
                            {{ user.username }}
                        </span>
                        <span class="font-thin">
                            {{ get_time(Date.now()/1000 - message.timestamp) }}
                        </span>
                    </div>
                    <span>{{ message["message"] }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            No chat active
        </div>

        <div v-if="!chatStore.timeout"
             class="flex z-10 backdrop-brightness-75">
            <textarea type="text" 
                class="bg-inherit border-none grow focus:outline-none rounded h-16 resize-none" 
                v-model="extra_data.current_message" 
                @keydown.enter.exact.stop.prevent="send_chat()"
                id="chat-input"
            />
            <div class="h-full pr-3 hover:text-field-color text-field-light-color cursor-pointer flex items-center"
                @click="send_chat()"
            >
                <ChatBubbleLeftIcon 
                    class="w-6 h-6 "
                />
            </div>
                 
        </div>
    </div>
</div>
</template>

<style>
</style>

