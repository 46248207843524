<script setup>
import { reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'
import { setPageTitle } from '../assets/js/titleManager.js';
import { MegaphoneIcon, BoltIcon, ChevronDoubleUpIcon, PencilSquareIcon , ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import MatchList from './MatchList.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import Countdown from './Countdown.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

const extra_data = reactive({
    offset:0,
    user_data: [],
    match_list: [],
    show_leaderboard: false,
    show_winlose: true,
    can_queue: true,
    can_signup: true,
    info: null
});

const tournament_data = reactive({
    join_tournament_id: null,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

onMounted(() => {
    reload();
    matchStore.setup();
});

function reload(){
    get_tournament_info();
}

async function get_tournament_info(){
    var url = app_server + `/tournament/${tournament_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();

    Object.assign(extra_data, data);

    extra_data.current_round = Math.max((extra_data.rounds || []).length - 1, 0);

    if(data.matches){
        extra_data.match_list.push(...data.matches);
    }
    console.log(data);

    if (extra_data.info && extra_data.info.name) {
        setPageTitle(extra_data.info.name);
    }
}

async function register(){
    const response = await fetch(app_server + `/tournament/${ tournament_id }/register/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    if(data.status == "success"){
        messageStore.alertUser("Registered", "You registered for the tournament, good luck!");
        reload();
    }
}

async function unregister(){
    const response = await fetch(app_server + `/tournament/${ tournament_id }/unregister/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    if(data.status == "success"){
        messageStore.alertUser("Unregistered", "You unregistered for the tournament.");
        reload();
    }
}

function is_queue_active(){
    const start_stop = extra_data.times[0] || [0, 0];

    const start = new Date(start_stop[0]);
    const stop = new Date(start_stop[1]);
    const now = new Date();

    console.log(start, now, stop, start <= now, now <= stop);

    return start <=  now && now <= stop;
}

function get_tournament_times(){
    const times = extra_data.times.map( (start_stop) => {
        const start = (new Date(start_stop[0])).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        const stop =(new Date(start_stop[1])).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        const day = day_names[(new Date(start_stop[0])).getDay()];
        return `${start} - ${stop} (${day})`;
    });
    
    const unique_times = times.filter( (item, pos, ary) => {
        return !pos || item != ary[pos - 1];
    });

    return unique_times;
}

function to_timecontrol_text(time_control){
    const control = time_controls.match;
    let timecontrol = control.find((x) => x[1] == time_control);

    if(timecontrol == null){
        timecontrol = [time_control, time_control];
    }
    return timecontrol; 
}

const day_names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const structure_explanation = {
    "single-elimination": "A single knock-out format, lose and you are out of the tournament.",
}

function join_chat_lobby(){
    const lobby = extra_data.info.name.replace(/\s/g, ''); 

    const chat = chatStore.join_lobby(lobby);

    if(chat != null){
        messageStore.alertUser("Joined Lobby", "You joined the tournament lobby.");
    }
}

function change_round(step){
    const max_rounds = (extra_data.rounds != null) ? extra_data.rounds.length - 1 : 0;
    console.log(max_rounds);
    extra_data.current_round = Math.min(
        Math.max(extra_data.current_round + step, 0), 
        max_rounds
    );
}
</script>

<template>
<Header />
<Message />
<div class="flex flex-col md:flex-row gap-x-12 gap-y-8 md:h-rest">
    <div class="flex flex-col px-2 max-w-96">
        <!-- Tournament Information -->
        <div v-if="extra_data.info" class="">
            <h1 class="text-4xl font-semibold mt-4 flex items-center gap-x-8">
                <span>{{ extra_data.info.name }}</span>
                <router-link :to="{name: 'tournament-mod', params: {tournament_id: tournament_id}}"
                    v-if="extra_data.info.creator == userStore.info.user_id"
                >
                    <PencilSquareIcon class="btn btn-blue !p-0.5 size-6" /> 
                </router-link>

                <Tooltip text="Join the tournament lobby">
                <MegaphoneIcon 
                    class="size-6 transition opacity-60 hover:opacity-100 cursor-pointer" 
                    @click="join_chat_lobby()"
                />
                </Tooltip>
            </h1>
            <h2 class="text-lg mt-1 font-thin flex gap-x-2">
                Tournament Director: <UserName :user_id="extra_data.info.creator"/>
            </h2>
            <h2 class="text-lg mt-1 font-thin">
                Match Length: {{ extra_data.info.points }}
            </h2>
            <Tooltip :text="time_control_to_text(extra_data.info.time_control)">
                <h2 class="text-lg font-thin">
                    Time Control: {{ to_timecontrol_text( extra_data.info.time_control )[0] }} 
                </h2>
            </Tooltip>
            <Tooltip :text="structure_explanation[extra_data.info.structure]"
                direction="bottom">
                <h2 class="text-lg font-thin">
                    Structure: {{ extra_data.info.structure }} 
                </h2>
            </Tooltip>

            <h2 class="text-lg font-thin mt-4">
                <p class="font-normal">Upcoming time slots:</p>
                <p v-for="time in get_tournament_times()">
                    {{ time }}
                </p>
            </h2>
            <div v-if="extra_data.info.description && extra_data.info.description.length > 0"
                class="text-lg font-thin w-96 my-4 p-4 isolate bg-main-2-color rounded-sm shadow-md"   
            >
                <MarkdownRenderer 
                    :markdown="extra_data.info.description" 
                    :save="false" 
                />
            </div>
            <div v-if="extra_data.info.status == 'inactive'">
                <h2 class="text-lg font-semibold my-4 flex gap-x-2">
                    Users Registered: 
                    <Tooltip text="In case the tournament is full you will be placed in the reserve list.">
                        <span class="numeric-tabular">
                            {{ extra_data.users_registered }}
                            <span v-if="extra_data.info.max_participants > 0">
                                / {{ extra_data.info.max_participants }}
                            </span>
                        </span>
                    </Tooltip>
                </h2>
                <h2 class="font-light">
                    Registration closes at: {{ new Date(extra_data.info.start_time * 1000).toLocaleString() }}
                </h2>
                <ButtonLoad class="btn btn-blue mt-2 flex-grow w-80 h-10" 
                        v-if="extra_data.can_signup"
                        :fn="() => register(tournament_id)"
                >
                    Register
                </ButtonLoad>
                <div v-else-if="extra_data.is_registered > 0" 
                    class="flex gap-x-2 items-center font-light"
                >
                    You registered at place 
                    <Tooltip text="In case the tournament is full you will be placed in the reserve list.">
                        <span class="numeric-tabular"
                              :class="{'text-accent-3-color font-semibold': extra_data.is_registered < extra_data.info.max_participants || extra_data.info.max_participants == 0}"
                        >
                            {{ extra_data.is_registered }}
                            <span v-if="extra_data.info.max_participants > 0">
                                / {{ extra_data.info.max_participants }}
                            </span>
                        </span>
                    </Tooltip>
                    <ButtonLoad class="btn btn-yellow mt-2" 
                            :fn="() => unregister(tournament_id)"
                    >
                        Unregister
                    </ButtonLoad>
                </div>
            </div>

            <div v-if="extra_data.info.status == 'active'">
                <ButtonLoad class="btn btn-blue mt-2 flex-grow w-80 h-10" 
                        v-if="extra_data.can_queue"
                        :fn="() => matchStore.play_tournament(tournament_id)"
                        :disabled="!is_queue_active() || !extra_data.can_play"
                >
                    I'm Ready!
                </ButtonLoad>
                
            </div>
        </div>
    
        <!-- Leaderboard -->
        <div class="w-full md:w-80 md:block">
            <span class="flex justify-between items-center">
                <h1 class="text-4xl font-semibold mt-4">Leaderboard</h1> 
                <button class="btn btn-blue md:hidden py-2 text-sm h-6" 
                        v-show="!extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = true"
                >
                    Show
                </button>
                <button class="btn btn-red md:hidden text-sm"
                        v-show="extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = false"
                >
                    Hide
                </button>
            </span>
            <table class="w-full h-full overflow-y-auto my-4" 
                   :class="{
                        'table': extra_data.show_leaderboard, 
                        'md:table hidden': !extra_data.show_leaderboard
                    }"
            >
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-left w-36">User</th>
                        <th>
                            <span v-show="extra_data.show_winlose">W/L</span>
                            <span v-show="!extra_data.show_winlose">Score</span>
                        </th>
                        <th v-if="extra_data.info && extra_data.info.status == 'finished'">
                            MP
                        </th>
                        <th>ER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user_data, index in extra_data.user_data">
                        <td class="">{{ user_data.rank }})</td> 
                        <td class="text-left text-elipsis">
                            <div class=" flex gap-x-2 items-center">

                            <div v-if="extra_data.info.disqualified.includes(user_data.user.user_id)"
                                 class="font-bold"
                            >
                                DQ
                            </div>
                            <UserName :user="user_data.user" />

                            <span v-if="user_data.streak >= 3">
                                <Tooltip :text="`Current Streak of ${user_data.streak}`"
                                        direction="bottom"
                                >
                                    <BoltIcon class="size-6" 
                                              :class="{
                                                    'text-field-med-color': user_data.streak >= 3 && user_data.streak < 5,
                                                    'text-field-color': user_data.streak >= 5 && user_data.streak < 10,
                                                    'text-brilliant-color': user_data.streak >= 10,
                                                }"
                                    />
                                </Tooltip>
                            </span>
                            <span v-if="user_data.rank_diff >= 3">
                                <Tooltip :text="`Quick riser, rose ${user_data.rank_diff}`"
                                        direction="bottom"
                                >
                                    <ChevronDoubleUpIcon class="size-6" 
                                              :class="{
                                                    'text-field-med-color': user_data.rank_diff >= 3 && user_data.rank_diff < 5,
                                                    'text-field-color': user_data.rank_diff >= 5 && user_data.rank_diff < 10,
                                                    'text-brilliant-color': user_data.rank_diff >= 10,
                                                }"
                                    />
                                </Tooltip>
                            </span>
                            </div>
                        </td>
                        <td class="text-center tabular-nums cursor-pointer" @click="extra_data.show_winlose ^= true">
                            <span v-show="extra_data.show_winlose">
                                {{ user_data.wins }}/{{user_data.losses }}
                            </span>
                            <span v-show="!extra_data.show_winlose">
                                {{user_data.score.toFixed(2)}}
                            </span>
                            </td>
                        <td v-if="extra_data.info && extra_data.info.status == 'finished'" class="text-right tabular-nums">
                            {{ user_data.master_points.toFixed(1) }}
                        </td>
                        <td class="text-right tabular-nums">{{ user_data.er.toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="grow md:h-full">
        <h1 class="text-4xl font-semibold mt-4 mb-4 flex items-center gap-x-4 justify-center">
            <ChevronLeftIcon 
                class="size-8 hover:text-accent-3-color cursor-pointer"
                :class="{'invisible' : extra_data.current_round == 0}"
                @click="change_round(-1)"
            />
            Round {{ extra_data.current_round + 1 }} Matches
            <ChevronRightIcon
                class="size-8 hover:text-accent-3-color cursor-pointer"
                :class="{'invisible' : extra_data.current_round >= (extra_data.rounds || []).length - 1}"
                @click="change_round(1)"
            />
        </h1>
        <MatchList 
            :matchlist_url="`/tournament/${tournament_id}/matches/round/${extra_data.current_round || 0}/`"
            :tournament_id="tournament_id"
        />
        <h1 class="text-4xl font-semibold mt-4 mb-4 text-center">Tournament Matches</h1>
        <MatchList 
            :matchlist_url="`/tournament/${tournament_id}/matches/`"
            :tournament_id="tournament_id"
        />
    </div>
</div>
</template>


