<script setup>

import {ref, computed, reactive, watch, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { SparklesIcon, FireIcon, ClockIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'
import Username from './UserName.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const play_server = import.meta.env.VITE_WEBSOCKET_SERVER;
const router = useRouter();

const match_list = reactive([]);
const props = defineProps({
    matches : {
        type: Array,
        default: null,
    },
    matchlist_url: {
        type: String,
        default: "/match/list/",
    },
    tournament_id: {
        type: String,
        default: "",
    },
    no_updates: {
        type: Boolean,
        default: false,
    },
    match_type: {
        type: String,
        default: "pvp",
    },
    match_status: {
        type: Array,
        default: ["active", "playing", "finished"],
    }
});

const extra_data = reactive({
});

watch( () => props.matchlist_url, (newvar, oldvar) =>{
    reload();
});

onMounted(() => {
    if(props.matches == null){
        reload();
        document.addEventListener("visibilitychange", visibility_change_update);
    }else{
        match_list.push(...props.matches);
        console.log(match_list);
        for(let match of match_list){
            if(match.match_length == null){
                match.match_length = match.points;
            }
        }
    }
});

function visibility_change_update(){
    if(document.visibilityState === "visible") {
        reload();
    }
}

const emit = defineEmits([
    'join-request',
]);


onUnmounted(() => {
    document.removeEventListener("visibilitychange", visibility_change_update);
});

async function reload(){
    sseStore.connect();
    if(props.matches != null && props.no_updates){
        return;
    }
    if(await userStore.loadUser()){
        const temp_match_list = [];
        await get_joinrequests(temp_match_list);
        await get_matches(temp_match_list);

        match_list.length = 0;
        match_list.push(...temp_match_list);

        if(!props.no_updates){
            add_sse_listeners();
        }
    }else{
        // we could not load the user, log out and redirect to the homepage
        messageStore.alertUser("User not logged in", 
            "Please log in to create and join matches"
        );
        userStore.logout();
        setTimeout(() => router.push({name:"frontpage"}), 2000);
    }
    sort_matchlist();
}

function is_match_in_progress(){
    return localStorage.getItem("current_match") !== null;
}

function sort_matchlist(){
    const status_score = {
        "open": 0,
        "active": 0,
        "playing": 1,
        "finished": 2,
        "cancelled": 3,
        null: 4,
    };
    match_list.sort( (a,b) => {
        if(a.status == b.status){
            return b.create_time - a.create_time; 
        }else{
            return status_score[a.status] - status_score[b.status];
        }
    });
    console.log("Sorted matchlist");
}

function add_sse_listeners(){
    sseStore.addListener("match new", (data) => {
        if(props.tournament_id.length > 0 && props.tournament_id != data.tournament_id){
            console.log(props.tournament_id, data);
            return;
        }
        var match_type = props.match_type;
        if(match_type == "pvp"){
            match_type = "sync";
        }
        if(match_type && data.match.match_type != match_type){
            return;
        }

        const match_index = match_list.findIndex((m) => m.match_id == data.match_id);
        console.log("NEW MATCH!", match_index);

        if(data.match.white == null){
            data.match.white = data.match.creator_data;
            data.match.score = {"W":0, "B":0};
        }
        data.match.can_join = !(data.match.black && data.match.white);
        if (typeof data.match.score === 'string' || data.match.score instanceof String){
            data.match.score = JSON.parse(data.match.score);
        }
        if(match_index == -1){ // A new match!
            match_list.unshift(data.match);
        }else{
            match_list[match_index] = data.match;
        }
    });
        
    sseStore.addListener("match delete", (data) => {    
        // Remove the match to the match list
        console.log("match delete", data);
        const match_ids = [];
        if(data.match_id.constructor === Array){
            match_ids.push(...data.match_id);
        }else{
            match_ids.push(data.match_id);
        }
        console.log(match_ids);
            
        for(let match_id of match_ids){
            const match_index = match_list.findIndex((m) => (m.match_id == match_id));
            if(match_index >= 0){
                match_list.splice(match_index, 1);
            }else{
                console.error(`Could not find ${match_id}`);
            }
        }
    });

    sseStore.addListener("match update", (data) => {
        // Update the match to the match list
        const match_index = match_list.findIndex((m) => m.match_id == data.match_id);
        if(match_index >= 0){
            console.log("Updata match", data);
            match_list.splice(match_index, 1, data.match);
        }else{
            console.error(`Could not update ${data.match_id}`);
        }
    });
}

async function get_joinrequests(temp_match_list=null){
    if(props.tournament_id.length > 0){
        return;
    }
    if(temp_match_list == null){
        temp_match_list = match_list;
    }
    var get_parameters = ``
    var match_type = props.match_type;
    if(match_type == "pvp"){
        match_type = "sync";
    }
    if(match_type){
        get_parameters = `?match_type=${match_type}`;
    }

    const response = await fetch(app_server + "/matchmaking/" + get_parameters,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json(); 

    if(data.status == "success"){
        for(const req of data.joinrequests){
            req.white = req.creator_data;
            req.score = {"W":0, "B":0}
            req.can_join = true;
        }
        temp_match_list.unshift(...data.joinrequests.filter( x => !temp_match_list.map(m => m.match_id).includes(x.match_id)));
    }else{
        if(data.code == 403){
            messageStore.alertUser("Play Timeout", "You currently cannot play matches due to a timeout.");
            matchStore.timeout=true;
        }
    }
}

async function get_matches(temp_match_list=null){
    /*
        Loads the match list from the server. The match list consists of the
        games that are searching for players (active) and that are currently 
        being played (playing).
    */
    if(temp_match_list == null){
        temp_match_list = match_list;
    }
    var get_parameters = `?` + props.match_status.map( x => `status=${x}`).join("&");
    if(props.match_type){
        get_parameters += `&match_type=${props.match_type}`;
    }

    const response = await fetch(app_server + props.matchlist_url + get_parameters,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();

    if(data.matches){
        const current_match_ids = temp_match_list.map( x => x.match_id);
        for(const i in data.matches){
            const match = data.matches[i];
            if(current_match_ids.includes(match.match_id)){
                continue;
            }
            match.can_join = !(match.black && match.white);
            if (typeof match.score === 'string' || match.score instanceof String){
                match.score = JSON.parse(match.score);
            }
            if(match.match_length == null){
                match.match_length = match.points;
            }
            temp_match_list.push(match);
        }
    }
    console.log(match_list);
}

function play_disabled(match){
    if((match.white && match.white.user_id == userStore.info.user_id) || (match.black && match.black.user_id == userStore.info.user_id)){
        return true;
    }
    
    if( match.match_length == 0 && Math.ceil(userStore.info.coins / 16) < match.stake){
        return true;
    }

    if( match.joined ){
        return true;
    }
    return false;
}

function to_timecontrol_string(match){
    let control;
    if(match.match_length == 0){
        control = time_controls.unlimited;
    }else if(match.match_type == 'async'){
        control = time_controls.daily;
    }else{
        control = time_controls.match;
    }
    let timecontrol = control.find((x) => x[1] == match.time_control);

    if(timecontrol == null){
        timecontrol = [match.time_control, match.time_control];
    }
    return timecontrol; 
}

function get_rating_delta(user){
    return {
        elo: (user.new_elo == null) ? 0 : user.new_elo - user.elo,
        rating: (user.new_rating == null) ? 0 : user.new_rating - user.rating,
        coins: (user.new_coins == null) ? 0 :  user.new_coins - user.coins,
    }
}

</script>
<template>
<div class="w-full grid grid-cols-1 md:grid-cols-1 gap-y-4 place-items-center">
    <button 
        class="btn btn-blue relative my-2 w-1/3"
        v-show="sseStore.connected == false && props.matches == null"
        @click="reload()"
    >
        Reconnect
    </button>
    <div v-if="match_list.length == 0"
        class="w-full text-center"
    >
        Currently there are no matches.
    </div>
    <div class="match-list box bg-main-2-color grid w-full rounded-xl py-4 px-2 lg:px-8 shadow-lg relative group"
         v-for="match in [...match_list]"
         :class="{
            '!bg-accent-1-color': match.can_join || ['open', 'playing'].includes(match.status),
         }"
    >
        <div class="flex items-center grow">
            <div class="flex-col flex gap-y-2 truncate"
                 :set="delta=get_rating_delta(match.white)"
            >
                <Username 
                    :user="match.white"
                    class="text-base md:text-lg font-semibold gap-x-1 truncate"
                    :class="{'text-lg': match.white.username.length < 5, 
                             'text-sm': match.white.username.length >= 7}"
                />
                <span v-if="match.match_length > 0" 
                    class="flex align-center items-center"
                >
                    <FireIcon class="w-6 h-6"/> {{ match.white.rating.toFixed(0) }}
                    <span v-if="delta.rating"
                          class="text-sm text-light mx-2 group-hover:visible invisible"
                          :class="{
                            'text-accent-color': delta.rating > 0,
                            'text-blunder-color': delta.rating < 0,
                          }"
                    >
                        <span v-if="delta.rating > 0">+</span>
                        {{ delta.rating.toFixed(1) }}
                    </span>
                </span>
                <span v-else class="flex align-center items-center">
                    <SparklesIcon class="w-6 h-6"/> {{ match.white.coins }}
                    <span v-if="delta.coins"
                      class="text-sm text-light mx-2 group-hover:visible invisible"
                      :class="{
                        'text-accent-color': delta.coins > 0,
                        'text-blunder-color': delta.coins < 0,
                      }"
                    >
                        <span v-if="delta.coins > 0">+</span>
                        {{ delta.coins.toFixed(0) }}
                    </span>
                </span>
                <span class="flex align-center" v-if="match.status == 'finished'">
                    ER: {{ match.white.er.toFixed(2) }}
                </span>
            </div>
        </div>

        <div class="flex-col flex whitespace-no-wrap justify-center items-center gap-y-2">
            <span class="" v-if="match.match_length == 0">
                Unlimited: 
                <SparklesIcon class="w-6 h-6 inline-block"/>
                {{ match.stake}}
            </span>
            <span class="" v-else>
                {{ match.match_length }} Point
            </span>
            <Tooltip :text="time_control_to_text(match.time_control)"
            >
                <span class="flex align-center whitespace-no-wrap">
                    <ClockIcon class="w-6 h-6"/> {{ to_timecontrol_string(match)[0] }}
                </span>
            </Tooltip>
            <span>
                <router-link 
                        v-if="match.status == 'finished'"
                        :to="{name: 'analysis', params:{match_id: match.match_id}}">
                    <button class="btn btn-yellow">Analysis</button>
                </router-link>
                <router-link 
                        v-else-if="match.status == 'playing' && match.white.user_id != userStore.info.user_id && match.black.user_id != userStore.info.user_id"
                        :to="{name: 'spectate', params:{match_id: match.match_id}}">
                    <button class="btn btn-yellow">Spectate</button>
                </router-link>
                <router-link v-else-if="match.status == 'playing' && match.match_type == 'async'"
                        :to="{name: 'daily', params:{match_id: match.match_id}}">
                    <button class="btn btn-blue">Play</button>
                </router-link>
                <router-link v-else-if="match.status == 'playing'"
                        :to="{name: 'match', params:{match_id: match.match_id}}">
                    <button class="btn btn-blue">Reconnect</button>
                </router-link>
            </span>
            <div class="flex items-center w-full">
                <div class="text-2xl text-left"
                     :class="{
                         'font-thin': match.score.W <= match.score.B, 
                         'font-semibold': match.score.B > match.score.W
                     }"
                >
                    {{ match.score.W }}
                </div>
                <div class="grow text-center">-</div>
                <div class="text-2xl text-right"
                     :class="{
                         'font-thin': match.score.B <= match.score.W, 
                         'font-semibold': match.score.B > match.score.B
                     }"
                >
                    {{ match.score.B }}
                </div>
            </div>
        </div>

        <div class="flex items-center justify-end grow"
            v-if="match.black"
            :set="delta=get_rating_delta(match.black)"
        >
            <div class="flex-col flex gap-y-2 truncate justify-end">
                <Username 
                    :user="match.black"
                    class="text-base md:text-lg font-semibold gap-x-1 truncate"
                    :class="{'text-lg': match.black.username.length < 5, 
                             'text-sm': match.black.username.length >= 7}"
                />
                <span v-if="match.match_length > 0" 
                    class="flex justify-end items-center">
                    <span v-if="delta.rating"
                      class="text-sm text-light mx-2 group-hover:visible invisible"
                      :class="{
                        'text-accent-color': delta.rating > 0,
                        'text-blunder-color': delta.rating < 0,
                      }"
                    >
                        <span v-if="delta.rating > 0">+</span>
                        {{ delta.rating.toFixed(1) }}
                    </span>
                     {{ match.black.rating.toFixed(0) }} <FireIcon class="w-6 h-6"/>
                </span>
                <span v-else class="flex justify-end items-center">
                    <span v-if="delta.coins"
                      class="text-sm text-light mx-2 group-hover:visible invisible"
                      :class="{
                        'text-accent-color': delta.coins > 0,
                        'text-blunder-color': delta.coins < 0,
                      }"
                    >
                        <span v-if="delta.coins > 0">+</span>
                        {{ delta.coins.toFixed(0) }}
                    </span>
                     {{ match.black.coins }} <SparklesIcon class="w-6 h-6"/>
                </span>
                <span class="flex align-center justify-end" v-if="match.status == 'finished'">
                    ER: {{ match.black.er.toFixed(2) }}
                </span>
            </div>
        </div>
        <div class="flex-col flex gap-y-2 justify-center"
            v-else >
            <div class="flex justify-center items-center gap-x-4">
                <button class="btn btn-blue p-4 h-12 w-1/3"
                        v-if="match.can_join"
                        @click="matchStore.join_joinrequest(match.match_id); match.joined=true;"
                        :disabled="play_disabled(match)"
                >
                   Play 
                </button>
                <button class="btn btn-blue p-4 h-12 max-w-1/2"
                        v-if="play_disabled(match) && match.white.user_id == userStore.info.user_id"
                        @click="matchStore.remove_joinrequest(match.match_id)"
                >
                   Cancel
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.match-list{
    grid-template-columns: 1fr min-content 1fr;
}

</style>
