<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CalendarIcon, CalculatorIcon, BookOpenIcon, PuzzlePieceIcon, BoltIcon, AcademicCapIcon, ChatBubbleLeftRightIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import Header from './Header.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const options = [
    {
        "name": "Blog",
        "description": "Read our in depth blog articles to learn more about backgammon.",
        "icon": AcademicCapIcon,
        "router_to": () => router.push({name:'blog-list'}),
        "can_view": () => {return true},
    },
    {
        "name": "Position Analyser",
        "description": "Do you want to set up a position to analyse? You have come to the right place.",
        "icon": CalculatorIcon,
        "router_to": () => router.push({name: 'position', params:{position_id: '11ccccchhhjjjjj:66666888dddddoo:N0N:32:B:R:0:0:0:0'}}),
        "can_view": () => {return true},
    },
    {
        "name": "Courses (beta)",
        "description": "Study backgammon by following the lessons.",
        "icon": BookOpenIcon,
        "can_view": () => {return userStore.has_permission("lesson", "view")},
        "router_to": () => router.push({name: 'course-list'}),
    },
];

const extra_data = reactive({
    selected_choice: options[0],
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

async function create_quiz_for_user(){
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "user",
            quiz_user_id: userStore.info.user_id, 
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        console.log("SUCCESS!!!!");
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
    
async function create_custom_quiz(){
    const quiz_data = {
        "nrof_puzzles": custom_quiz_data.nrof_puzzles,
        "tags": Object.keys(custom_quiz_data.tags),
    };
    const response = await fetch(app_server + "/quiz/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            quiz_type: "custom",
            quiz_user_id: userStore.info.user_id, 
            ...quiz_data,
        }),
    });
    const data = await response.json();
    console.log("Quiz:", data);
    if(data.status == "success"){
        router.replace({name:"quiz", params:{quiz_id: data.quiz_id}});
    }
}
    

onMounted(() => {
});


</script>

<template>
    <Header />
<div class="flex flex-col">
     
    <div class="container mx-auto max-w-xl flex flex-col-reverse items-center gap-8">
        <div class="mx-auto w-96 bg-main-2-color flex 
             flex-col items-center gap-y-4 p-4 rounded-lg relative"
        >
            <h1 class="text-4xl font-bold"></h1>
            <div class="flex flex-col items-center gap-y-4">
                <component :is="extra_data.selected_choice.icon" 
                    class="size-24 overflow-hidden text-accent-3-color">
                </component>

                <p class="text-xl font-semibold">
                    {{ extra_data.selected_choice.name}}</p>
            </div>
            <p class="text-center min-h-[5em]">
                {{ extra_data.selected_choice.description }}
            </p>
            <div class="w-full flex flex-col gap-y-3"
               v-if="extra_data.selected_choice.options"
                 v-for="optiongroup in extra_data.selected_choice.options"
            >
                <div class="text-lg">
                    {{ optiongroup[0] }}
                </div>
                <div class="grid grid-cols-3 gap-x-2 gap-y-2 w-full"
                     v-if="optiongroup[1]"
                >
                    <div v-for="option in optiongroup[1]"
                        class="h-12 rounded transition-all text-lg font-semibold
                            flex items-center justify-center cursor-pointer capitalize
                            ring-accent-3-color 
                            "
                        :class="{
                            'bg-accent-3-color text-light-color ring': option.is_active(option),
                            'bg-main-3-color text-dark-color': !option.is_active(option),
                        }"
                        @click="option.action(option)"
                    >
                        {{ option.title }}
                    </div>
                </div>
            </div>
            <button class="btn btn-blue h-12 w-1/3 text-2xl"
                @click="extra_data.selected_choice.router_to()"
                :disabled="extra_data.selected_choice.disabled"
            >
                Start
            </button>
        </div>
        <div class="grid grid-cols-3 gap-8"
        >
            <div class="size-24 bg-main-2-color p-2 rounded-lg 
                    ring-accent-3-color overflow-hidden relative cursor-pointer"
                 :class="{
                    'ring !bg-accent-1-color': option.name == extra_data.selected_choice.name
                 }"
                 v-for="option in options.filter((x) => x.can_view())"
                @click="extra_data.selected_choice = option"
            >

                <div class="flex flex-col items-center gap-y-4 text-accent-4-color">
                    <component :is="option.icon" 
                        class="size-16"
                        :class="{'': option.name == extra_data.selected_choice.name}"
                    >
                    </component>
                </div>
                <p class="font-thin text-center">
                    {{ option.name}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<style>
</style>


