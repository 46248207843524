export async function getPositionAnalysis(position_id, config=null, app_server_url=""){
    if(config == null){
        config = {};
    }

    var data = {
        "config": "1ply",
        "move_played": null,
        "engine_id": "gnubg",
    };
    Object.assign(data, config);

    const response = await fetch(app_server_url + `/position/${ position_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    const analysis_data = await response.json();
    
    if(analysis_data.status == "error"){
        return;
    }
    return analysis_data;
}

export function count_direct_shots(board, color="W"){
    var direct_shots = 0;
    for(let point_id in board.points){
        const point = board.points[board.get_point_index(point_id)];
        if(point.color == board.opponent[color] || point.nrof_stones == 0 || point.nrof_stones > 1){
            continue;
        }

        for(let i=1; i<=6; i++){
            if(point_id - i < 0){
                continue;
            }
            const point = board.points[board.get_point_index(point_id - i)];
            if(point.color == board.opponent[color] && point.nrof_stones > 0){
                direct_shots++;
            }
        }
    }
    
    return direct_shots;
}

export function get_random_move(board){
    const valid_states = Object.values(board.getValidStates());
    var action;
    if(valid_states.length == 0){
        // console.debug("No valid states");
        action = board;
    }else{
        const actions = valid_states.map((x) => x[0][0]);
        const min_shot_actions = filter_min_direct_shot_actions(actions)
        const max_born_actions = filter_max_born_off(min_shot_actions);
        const running_actions = filter_running_actions(max_born_actions);
        
        action = running_actions[
            Math.floor(Math.random() * running_actions.length)];
        // console.log("Choosing 'random' move");
    }
    return action;
}

export async function find_engine_move(bot_obj, board, config, 
    app_server_url="", rank=0){
    var action = null;
    var new_board = board.copy();

    if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
        const player_color = board.opponent[board.color];
        new_board.game_state = "R";

        var analysis = await getPositionAnalysis(
            new_board.toPositionString(), config, app_server_url);

        while(bot_obj.analysis_data.analysis_id != analysis.request.analysis_id){
            await new Promise(r => setTimeout(r, 100)); 
        }

        analysis = bot_obj.analysis_data;

        if(analysis.checker.moves.length == 0){
            // console.log("GNU could not find a move");
            action = get_random_move(board);
        }else{
            rank = Math.min(rank, analysis.checker.moves.length - 1);
            const best_move = analysis.checker.moves[rank].move;
            for(const move of best_move.data){
                const to = move[1] == 0 ? -1 : move[1];
                new_board = new_board.moveStoneAbs(move[0], to, player_color);
            }
            
            action = new_board;
        }
    } else if(board.game_state == "D"){
        var analysis = await getPositionAnalysis(
            new_board.toPositionString(), config, app_server_url);

        while(bot_obj.analysis_data.analysis_id != analysis.request.analysis_id){
            await new Promise(r => setTimeout(r, 100)); 
        }
        analysis = bot_obj.analysis_data;
        // console.log("Take/Pass", analysis);
        
        if(typeof analysis.cube == "object" && analysis.cube.double != null){
            analysis = analysis.cube;
        }else{
            return "take";
        }

        const recommendation = analysis.double["cube recommendation"].best;

        if(recommendation == "EQ DT"){
            action = "take";
        }else if(recommendation == "EQ DP"){
            action = "pass";
        }else{
            //console.error("Action not valid!", recommendation);
        }
    } else if(board.game_state == "C"){
        var analysis = await getPositionAnalysis(
            new_board.toPositionString(), config, app_server_url);

        while(bot_obj.analysis_data.analysis_id != analysis.request.analysis_id){
            await new Promise(r => setTimeout(r, 100)); 
        }
        analysis = bot_obj.analysis_data;

        if(typeof analysis.cube == "object" && analysis.cube.double != null){
            analysis = analysis.cube;
        }else{
            return "take";
        }

        const recommendation = analysis.double["cube recommendation"].best;

        if(recommendation == "EQ DT" || recommendation == "EQ DP" ){
            action = "double";
        }else{
            action = "roll";
        }    
    }
    return action;
}

function running_value(board){
    const pipcount = {"W": 0, "B": 0};
    for(const point of board.points){
        if(point.color == "W"){
            pipcount[point.color] += point.nrof_stones * (25 - point.id)**2;
        }else if(point.color == "B"){
            pipcount[point.color] += point.nrof_stones * (point.id**2);
        }
    }
    return pipcount;
}

export function filter_running_actions(actions){
    const last_checker = Math.min(...actions.map((x) => running_value(x).B));
    // console.debug(last_checker);
    const running_actions = actions.filter(
        (x) => running_value(x).B == last_checker);
    //console.debug(running_actions);

    return running_actions;
}

export function filter_min_direct_shot_actions(actions, color="W"){
    const min_shots = Math.min(...actions.map((x) => count_direct_shots(x, color)));
    
    const min_shot_actions = actions.filter(
        (x) => count_direct_shots(x, color) == min_shots);
    //console.log("Min shots:", min_shots, min_shot_actions);
    return min_shot_actions;
}

export function filter_max_born_off(actions){
    const max_born_off = Math.max( ...actions.map( x => x.get_nrof_born_off().B));
    const max_born_off_checkers = actions.filter(x => x.get_nrof_born_off().B == max_born_off);
    //console.log("MAX born off:", max_born_off, max_born_off_checkers);
    return max_born_off_checkers;
}
