<script setup>
/*
*/
import {ref, watch, computed, reactive, onMounted} from 'vue'

import UserFind from './UserFind.vue'
import UserName from './UserName.vue'
import ButtonLoad from './ButtonLoad.vue'

import { useRoute, useRouter } from 'vue-router'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const flair_data = reactive({
    flair_id: "",
    name: "",
    description: "",
    tag: "",
    color: "",
    max_grants: "",
});

const lessons_data = reactive({

});

const extra_data = reactive({
    grant_to_user_id: null,
});

var flair_id = undefined;

watch(() => route.params.flair_id, (a, b) =>{
    flair_id = route.params.flair_id;
});

onMounted(async () => {
    flair_id = route.params.flair_id;
    await get_flair(flair_id);
});

async function get_flair(flair_id){
    const response = await fetch(app_server + `/admin/flair/${ flair_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },

    });
    const data = await response.json();
    Object.assign(flair_data, data.flair);

    extra_data.users = data.users;

    console.log(data);

    return data.flair;
}

async function save_flair(){
    route.params.flair_id = flair_data.flair_id;
    var url = `/admin/flair/${flair_id}/`;
    
    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(flair_data),

    });
    const data = await response.json();
    
    get_flair(flair_id);
}

async function grant_flair(){
    route.params.flair_id = flair_data.flair_id;
    var url = `/admin/flair/${flair_id}/grant/`;
    
    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: extra_data.user_to_grant.user_id,
        }),
    });
    const data = await response.json();
    
    get_flair(flair_id);
}

async function remove_flair(user_id){
    route.params.flair_id = flair_data.flair_id;
    var url = `/admin/flair/${flair_id}/grant/`;
    
    const response = await fetch(app_server + url, {
        method: "DELETE",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: user_id,
        }),
    });
    const data = await response.json();
    
    get_flair(flair_id);
}
</script>

<template>
    <div class="container flex flex-col gap-y-4 mx-auto max-w-prose">
        <h1 class="text-2xl">Edit Flair</h1>
        <label v-for="val, field in flair_data">
            <span class="capitalize">{{ field }}</span>
            <textarea 
                v-model="flair_data[field]"
                v-if="['description'].includes(field)"
                :id="field"
                class="w-full"
            ></textarea>
            <input 
                class="block rounded border-0 py-1.5 shadow-sm"
                v-model="flair_data[field]"
                v-else
                :id="field"
            />
        </label>
        
        <button class="btn btn-blue" @click="save_flair()">
            Save
        </button>
        <h1 class="text-2xl">Users</h1>
        <div class="flex gap-x-2 items-center">
            <span v-if="extra_data.user_to_grant == null">No user selected</span>
            <UserName v-else :user="extra_data.user_to_grant" />
            <UserFind @selecteduser="(x) => extra_data.user_to_grant = x" />
            <ButtonLoad 
                class="btn btn-blue" 
                :fn="grant_flair"
                :disabled="extra_data.user_to_grant == null"
            >
                Grant Flair
            </ButtonLoad>
        </div>
        <div v-for="user, user_id in extra_data.users"
             class="flex w-96 justify-between gap-x-4"
        >
            {{ user }}
            <ButtonLoad 
                class="btn btn-yellow text-sm font-base" 
                :fn="() => remove_flair(user_id)"
            >
                Remove Flair
            </ButtonLoad>
        </div>

    </div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
}
</style>



