<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    total_nrof_flairs: 0,
    offset: 0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const flair_list = reactive([]);
onMounted(() => {
    load_flair_list();
});

async function load_flair_list(){
    var url = app_server + "/admin/flair/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    console.log(data);
    flair_list.length = 0;// empty the users list
    if(data.flairs){
        flair_list.push(...data.flairs);
        extra_data.total_nrof_flairs = data.total_nrof_flairs;
    }
}

function next_page(){
    extra_data.offset += 100;
    load_flair_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 100);
    load_flair_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div>
        <router-link :to="{name: 'admin-flair-detail', params: {'flair_id': 'new'}}">
        <button class="btn btn-blue">
            Create Flair
        </button>
        </router-link>
        <div>
            <div>Total flairs: {{ extra_data.total_nrof_flairs }}</div>
            <div>Offset: {{ extra_data.offset }}</div>
        </div>
    </div>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">ID</th> 
        <th scope="col" class="sticky top-0 z-10">Name</th> 
        <th scope="col" class="sticky top-0 z-10">Description</th> 
        <th scope="col" class="sticky top-0 z-10">Grants</th> 
        <th scope="col" class="sticky top-0 z-10">Tag</th> 
        <th scope="col" class="sticky top-0 z-10">Color</th> 
    </tr>
</thead>
<tbody>
    <tr v-for="flair, index in flair_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left text-sm">
            <router-link 
                :to="{name: 'admin-flair-detail', params:{
                    flair_id: flair.flair_id}
                }"
            >
                {{ flair.flair_id }}
            </router-link>
        </td>
        <td class="text-left">{{ flair.name }}</td>
        <td class="text-left truncate text-sm">{{ flair.description }}</td>
        <td class="text-right numeric-tabular">{{ flair.nrof_grants }}/{{ flair.max_grants }}</td>
        <td class="text-right">{{ flair.tag }}</td>
        <td class="text-right">{{ flair.color }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>



