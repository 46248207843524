<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import ButtonLoad from "./ButtonLoad.vue"

const app_server = import.meta.env.VITE_APP_SERVER;

function sleep(n) { return new Promise(resolve=>setTimeout(resolve,n)); }

const extra_data = reactive({
    token_requested: false,
    can_request_token: true,
    show: false,
    status: "",
    state: "idle",
});


const data = reactive({
    email: "",
    token: "",
});

const emit = defineEmits([
    'logged-in',
]);

onMounted(() => {

});

async function request_token(){
    extra_data.token_requested = false;
    data.token = ""; 
    login();
}

async function login(){
    if(extra_data.token_requested && data.token == ""){
        console.log("No token supplied");
        extra_data.status = "No token, please supply token.";
        return;
    }
    extra_data.state = "loading";

    await sleep(1000);
     
    extra_data.status = "Waiting...";

    const login_data = {
        "email": data.email,
    };
    
    if(data["token"]){
        login_data["token"] = data["token"];
    }

    const response = await fetch(app_server + "/user/login/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
        },
        body:JSON.stringify(login_data),
    });

    extra_data.state = "idle";
    const received_data = await response.json();

    if(received_data["status"] == "success"){
        extra_data.token_requested = true;
        if(received_data["jwt"]){
            // We successfully logged in   
            localStorage.setItem('jwt', received_data["jwt"]);
            extra_data.show = false;
            emit('logged-in');
        }
    }else{
        extra_data.status = received_data.message; 
        console.log("ERROR", received_data);
        return;
    }
    extra_data.status = "";
}

function cancel(){
    extra_data.show = false;
    data.email = "";
    data.token = "";
    extra_data.token_requested = false;
}

function can_request_token(){
    return data.email.length > 0;
}

</script>
<template>
<button class="btn btn-blue md:text-2xl md:w-1/3" @click="extra_data.show=true">Login</button>
<Teleport to="body">
<TransitionRoot as="template" :show="extra_data.show">
    <Dialog as="div" class="relative z-10" @close="cancel()">
      <TransitionChild as="template" enter="ease-out duration-300" 
            enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" 
            leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-main-1-color bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-top justify-center p-4 text-center items-center md:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" 
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enter-to="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leave-from="opacity-100 translate-y-0 sm:scale-100" 
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform rounded-lg bg-main-1-color px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <label for="email" class="block text-sm font-medium leading-6">
                    Email
                </label>
                <div class="flex w-full mb-2">
                    <input id="email" type="email" 
                       v-model="data.email" :disabled="extra_data.token_requested"
                       class="block rounded-l-md border-0 py-1.5 shadow-sm"
                       placeholder="email@example.com"
                       @keyup.enter="request_token()"
                    />
                    <ButtonLoad 
                        @click="request_token()" 
                        class="btn-blue btn !rounded-l-none !rounded-r-md px-3 py-2 text-sm"
                        :is_disabled="!can_request_token() || extra_data.token_requested"
                        :loading="extra_data.state == 'loading' && !extra_data.token_requested"
                    >
                        Get Token
                    </ButtonLoad>
                </div>
                
                <div v-if="extra_data.status" 
                    class="text-sm text-warning-color">
                    {{ extra_data.status }}
                </div>
                
                <div class="flex w-full my-8">
                    <div
                       v-if="extra_data.token_requested" 
                    >
                    <label for="tokeninput"
                            class="block text-sm font-medium leading-6 mb-2"
                    >The token you received:</label>
                    <input type="text" 
                            id="tokeninput"
                           autocomplets="one-time-code"
                           maxlength="6"
                           pattern="\w{6}"
                           v-model="data.token" 
                           class="tokeninput w-full mx-auto"
                           placeholder="" 
                           @keyup.enter="login()"
                    />
                    <p class="font-thin text-sm mt-2">It might be in your spambox.</p>
                    </div>
                    
                </div>
                 
                <ButtonLoad 
                    v-if="extra_data.token_requested" 
                    class="btn btn-blue w-full text-lg h-12" 
                    @click="login()"
                    :loading="extra_data.state == 'loading' && extra_data.token_requested"
                    :is_disabled="data.token.length < 1" 
                >
                    Login
                </ButtonLoad>
                <button class="btn btn-red w-full text-lg h-12" @click="cancel()">Cancel</button>
            
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</Teleport>
</template>

<style scoped>
form{
    display:inline-block;
}

.tokeninput{
    --otp-digits: 6;
    --otp-ls: 2ch;
    --otp-gap: 1.25;
    --otp-fz: 1.5em;
    --otp-bg: var(--main-2-color);
    --otp-cc: var(--main-5-color);

    /* private consts */
    --_otp-bgsz: calc(var(--otp-ls) + 1ch);
    --_otp-digit: 0;

    all: unset;
    background: 
        linear-gradient(90deg, 
        var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),
        transparent 0),
        linear-gradient(90deg, 
        var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
        transparent 0
    );
    /* background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;*/
    background-repeat: no-repeat, repeat-x;
    background-size: var(--_otp-bgsz) 100%;
    caret-color: var(--otp-cc, #222);
    caret-shape: block;
    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
    font-family: ui-monospace, monospace;
    font-size: var(--otp-fz, 2.5em);
    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otp-ls);
    padding-block: var(--otp-pb, 1ch);
    padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
}

</style>
