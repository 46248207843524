<script setup>
/*
*/
import Board from './Board.vue'
import Header from './Header.vue'
import Report from './Report.vue'
import Tooltip from './Tooltip.vue'

import {BoardState} from '../assets/js/board.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {ref, watch, computed, reactive, onMounted} from 'vue'

import { useRoute, useRouter } from 'vue-router'

import {QuestionMarkCircleIcon, CalendarIcon, CpuChipIcon, CheckCircleIcon, XCircleIcon, PuzzlePieceIcon, ShareIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const start_position = "11ccccchhhjjjjj:55666888dddddoo:N0N:23:W:R:0:0:0:0"

const state = reactive({
    current_question_index: -1,
    board: new BoardState(),
    player_color: "W",
});

const extra_data = reactive({
    active_question: {},
    edit_answer: false,
    editor_to_add: "",
});

const lesson_data = reactive({
    title: "",
    summary: "",
    status: "edit",
    introduction: "",
    conclusion: "",

    questions: [],
    editors: [],
    tags: [],
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});

const old_lesson_data = {};

var lesson_id = undefined;

onMounted(async () => {
    lesson_id = route.params.lesson_id;
    await get_lesson(lesson_id);
});

async function get_lesson(lesson_id){
    console.log("Loading lesson", lesson_id);
    if(lesson_id == null){
        return;
    }

    const response = await fetch(app_server + `/lesson/${ lesson_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    Object.assign(lesson_data, data.lesson);
    Object.assign(old_lesson_data, data.lesson);

    return data.lesson;
}

async function save_lesson(){
    const  elem = document.getElementById("lessonform");

    if(!elem.checkValidity()){
        console.log("Form not valid:", elem.checkValidity());
        elem.reportValidity();
        return;
    }

    if(lesson_data.title != old_lesson_data.title){
        const cont = confirm("By changing the lesson you will automatically change the slug which changes the human readable link for this lesson. Are you sure you want to continue?");

        if(!cont){
            lesson_data.title = old_lesson_data.title;
            return;
        }
    }


    var url = "/lesson/";
    if(lesson_data.lesson_id != null){
        url += lesson_data.lesson_id + "/";
    }

    console.log("SAVE:", url, lesson_data);

    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(lesson_data),

    });
    const data = await response.json();
    if(data.status == "success"){
        Object.assign(lesson_data, data.lesson);
        console.log("NEW LESSON", data);
        messageStore.alertUser("Success", "The lesson is saved.");
    }else{
        messageStore.alertUser("Validation Error", data.message);
        lesson_data.title = old_lesson_data.title;
    }

    router.replace({name: "lesson-create", 
        params: {"lesson_id": lesson_data.lesson_id}}
    );

    return data.lesson;
}

async function handleMove(positionString, action=null){
    if(extra_data.edit_answer){
        const state_machine = new StateMachine();
        state_machine.player_color = get_board_color();
        state_machine.roll_dice_callback = () => [];

        var solution = null;
        var new_board = state_machine.next_state(new BoardState(positionString), action);

        extra_data.active_question.solution = new_board.toPositionString();
    }else{
        extra_data.active_question.position = positionString;
        extra_data.active_question.solution = null;
    }
}

function get_move(){
    const alt_played = extra_data.solve_info.alt_played;
    if(extra_data.puzzle_info.puzzle_type == "checker" && alt_played >= 0){
        const move = extra_data.puzzle_info.analysis.moves.find( x => x.alt == alt_played)
        if(move){
            return move.move.repr;
        }else{
            return "No move";
        }
    }else if(extra_data.puzzle_info.puzzle_type == "double"){
        const double_solutions = {
            "ND" : "No Double",
            "DT" : "Double, Take",
            "DP" : "Double, Pass",
        };
        return double_solutions[extra_data.solve_info.solution];
    }else{
        if(extra_data.solve_info.solution && extra_data.puzzle_info.position){
            const bs = new BoardState( extra_data.puzzle_info.position);
            return bs.getMove(new BoardState(extra_data.solve_info.solution)).text;
        }   
    }
    return "No Move" 
}

function get_board_color(){
    const state = new BoardState(extra_data.active_question.position);
    return state.opponent[state.color];
}

</script>

<template>
<div class="w-screen h-rest flex-col relative">
    <Header />
    <div class="flex flex-col w-full md:flex-row md:relative h-full">
        <div class="w-full md:w-2/3 md:h-rest relative">
            <Board :positionString="extra_data.active_question.position" 
                   :player_color="get_board_color()" 
                   :clock_data="clock_data"
                   :arrows="extra_data.arrows"
                   :show_pip_numbers="true"
                   :edit_mode="! extra_data.edit_answer"
                   @move-end="handleMove"
            >
            </Board>
        </div>
        <form id="lessonform"
            class="flex flex-col gap-y-4 px-2 md:px-4 md:w-1/3 overflow-y-auto pb-16"
        >
            <label>Title
                <input type="text" 
                       class="text-xl w-full" 
                       placeholder="A catching title for your lesson"
                       v-model="lesson_data.title" 
                       required
                />
            </label>
            <label>Summary
                <textarea class="w-full" v-model="lesson_data.summary" 
                    placeholder="A short summary of the lesson that is shown in the Lesson list." 
                    required
                />
            </label>
            <label>Status:
                <select v-model="lesson_data.status" class="block" required>
                    <option :value="'edit'">Edit</option>
                    <option :value="'listed'">Listed</option>
                    <option :value="'unlisted'">Unlisted</option>
                </select>
            </label>
            <label>
                Introduction:
                <textarea class="w-full" 
                          v-model="lesson_data.introduction" 
                          placeholder="An introduction that is shown when the lesson starts."
                          required
                />
            </label>

            <label>
            Conclusion:
            <textarea class="w-full" 
                      v-model="lesson_data.conclusion" 
                      placeholder="Conclusion of the lesson, this text is shown after solving the last question."
                      required
            />
            </label>
            
            Questions:
            <div class="flex flex-col gap-y-4">
                <div class="flex flex-col gap-y-2 border p-2 bg-main-2-color" 
                    v-for="question, i in lesson_data.questions"
                    :class="{'!bg-accent-2-color border-accent-2-color':extra_data.active_question == question}"
                    @click="extra_data.active_question=question"
                >
                <h2 class="text-right">Question {{ i+1 }}</h2>
                    <label>
                        Introduction:
                        <textarea class="w-full" v-model="question.introduction" 
                            required
                        />      
                    </label>

                    <label :class="{'font-bold': !extra_data.edit_answer}"
                        @click="extra_data.edit_answer=false"
                    >
                        Position:
                        <input type="text" class="w-full" v-model="question.position" 
                              required
                        />
                    </label>

                    <label :class="{'font-bold': extra_data.edit_answer}"
                        @click="extra_data.edit_answer=true"
                    >
                        Solution:
                        <input type="text" class="w-full" v-model="question.solution" 
                                required
                        />
                    </label>

                    <label>Hint:
                        <textarea class="w-full" v-model="question.hint" required/>      
                    </label>
                    <label>Explanation:
                        <textarea class="w-full" v-model="question.explanation"  required/>      
                    </label>

                </div>
                <div v-if="lesson_data.author_id == userStore.info.user_id">
                    <span class="">Add editors:</span>
                    <ul class="mb-8">
                        <li v-for="editor in lesson_data.editors">
                            {{ editor }}
                            <button 
                                @click="lesson_data.editors = lesson_data.editors.filter(x => x != editor)"
                            >
                                remove
                            </button>
                        </li>
                    </ul>
                    <div class="flex gap-x-4">
                        <input v-model="extra_data.editor_to_add" />
                        <button class="btn btn-blue"
                            @click="lesson_data.editors.push(extra_data.editor_to_add)"
                        >
                            Add
                        </button>
                    </div>
                </div>
                <div class="flex justify-between">
                    <button class="btn btn-blue" 
                        @click="lesson_data.questions.push({
                            position: start_position,
                        })">
                        + Add Question
                    </button>
                    <button class="btn btn-blue" 
                        @click.prevent="save_lesson()"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div>
        
    </div>
</div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
}
</style>


