<script setup>
import Header from './Header.vue'
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/solid'

import { useRoute, useRouter } from 'vue-router'

const route = useRoute();
const router = useRouter();

const features = [
    "Zero Advertisement or Tracking",
    "Play Matches",
    "Play Unlimited games",
    "Play Daily Matches",
    "Arena Tournaments",
    "3-ply GnuBG Analysis",
    "Insights",
    "Puzzles",
    "Puzzle Rush",
    "Pip Rush",
    "Personalised Quiz",
    "Position Analysis",
    "Download your game",
    "Board Themes",
    "Chat",
    "Blog",
    "All future features",
];
</script>
<template>
<Header />
<div class="container mx-auto mb-8 px-2 lg:px-0 lg:w-1/2">

<h1 class="text-4xl font-bold mt-16">
    Features Comparison
</h1>

<table class="w-full">
    <tbody>
    <tr
        class="text-xl h-20 p-2"
    >
        <td>
            OpenGammon
        </td>
        <td class="text-center">
            Free Account
        </td>
        <td class="text-center">
            OpenGammon Patron 
        </td>
    </tr>
    <tr v-for="feature in features"
        class="h-12 p-2"
    >
        <td> {{ feature }} </td>
        <td> 
            <div class="text-center flex justify-center items-center">
                <CheckCircleIcon class="w-8 h-8 text-field-color" />
            </div>
        </td>
        <td> 
            <div class="text-center flex justify-center items-center">
                <CheckCircleIcon class="w-8 h-8 text-field-color" />
            </div>
        </td>
    </tr>
    <tr
        class="h-12 p-2"
    >
        <td> A Patron Flair </td>
        <td> 
            <div class="text-center flex justify-center items-center">
                <XCircleIcon class="w-8 h-8 text-stone-b-color" />
            </div>
        </td>
        <td> 
            <div class="text-center flex justify-center items-center">
                <CheckCircleIcon class="w-8 h-8 text-field-color" />
            </div>
        </td>
    </tr>
    </tbody>
</table>

<div class="text-case-light-color bg-field-med-color mx-1/4 my-16 px-10 py-6 rounded-lg
            flex flex-col gap-y-4"
>
    <h2 class="text-2xl">
        Yes, both accounts get the same features!
    </h2>
    <h2 class="text-lg">
        We believe that every Backgammon player should have access to the best tools.
    </h2>
    <h2 class="text-2xl">
        If you enjoy OpenGammon consider donating, or
    </h2>
    <router-link :to="{name: 'donate'}"
        class="btn btn-blue !bg-field-color !py-4 text-2xl text-center">
            Support us with a Patron account
    </router-link>
</div>
</div>

</template>

<style scoped>
</style>

