<script setup>
import {FlagIcon} from '@heroicons/vue/24/solid'

const props = defineProps({
    score:{
        type: Object,
    },
    crawford:{
        type: Boolean,
    },
    player_color:{
        type: String,
    },
    color:{
        type:String,
    },
    show_away:{
        type:Boolean,
        default: false,
    }
})

const emit = defineEmits([
    'resign',
    'change_show_away',
]);

function get_score(){
    return props.score[props.color];
}

function get_away(){
    const match_length = props.score.match_length;
    const score = get_score();
    if( match_length == 0){
        return null;
    }else{
        return `${match_length - score}A`
    }
}

</script>

<template>
    <div class="text-board-text-color flex flex-col items-center justify-center cursor-pointer" 
         @click.stop="emit('change_show_away')"
    >
        <p 
            class="text-[0.5rem] @md:text-sm font-thin text-center w-full justify-center">
            <span v-if="score.crawford">
                Crawford 
            </span>
            <span v-else-if="score.last_game">
                Last game
            </span>
            <span v-else-if="props.show_away">
                Away
            </span>
            <span v-else>
                Score 
            </span>
        </p>
        
        <span class="@md:text-lg @lg:text-2xl">
            <span v-if="props.show_away && get_away() != null">
                {{ get_away() }}
            </span>
            <span v-else-if="props.show_away && get_away() == null" 
                class="@md:text-2xl @lg:text-4xl"
            >
                &infin;
            </span>
            <span v-else>
                {{ get_score() }}
            </span>
        </span>
        <p v-if="score.match_length > 0"
            class="text-[0.5rem] @md:text-sm font-thin text-center whitespace-nowrap">
            Until {{ score.match_length }}
        </p>
        <p  v-else
            class="text-[0.5rem] @md:text-sm font-thin text-center whitespace-nowrap">
            Unlimited
        </p>
    </div>
</template>

<style scoped>
</style>
