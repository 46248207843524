<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {PlusIcon, UsersIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    tournaments: [],
});


onMounted(async () => {
    get_tournaments("active");
    get_tournaments("inactive");
    get_tournaments("finished");
});

async function get_tournaments(tournament_status="active"){
    var url = app_server + `/tournament/?status=${tournament_status}`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    if(data.status == "success"){
        extra_data.tournaments.push(...data.tournaments);
    }
    
    console.log(data);
}

async function create_tournament(){
    if(!confirm("Are you sure you want to create a new tournament?")){
        return;
    }

    var url = app_server + `/tournament/mod/new/`;
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const data = await response.json();

    if(data.status == "success"){
        router.push({name: 'tournament-mod', params:{tournament_id: data.tournament.tournament_id}});
    }
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12 tracking-wide flex items-center justify-center gap-x-4">
        Tournaments
        <PlusIcon class="size-8 hover:text-accent-3-color cursor-pointer"
            v-if="userStore.has_permission('tournament', 'moderate')"
            @click="create_tournament()"
        />
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color shadow rounded py-4 px-6 group relative transition-all"
            v-for="tournament in extra_data.tournaments"
            :class="{'!bg-accent-1-color': tournament.start_time*1000 > Date.now()}"
        >
            <div class="grow flex flex-col gap-y-2 w-full">
                <h2 class="text-3xl text-center tracking-wide font-medium 
                    mb-4 group-hover:text-accent-3-color w-full truncate"
                >
                    <router-link :to="{name: 'tournament-info', params:{tournament_id: tournament.tournament_id}}">
                            {{ tournament.name }}
                    </router-link>
                </h2>

                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Tournament Director">
                    <UserCircleIcon class="w-4 h-4" />
                    </Tooltip>
                    <UserName :user_id="tournament.creator" />
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Registration ends">
                        <ClockIcon class="size-4" />
                    </Tooltip>
                    <span>{{ new Date(tournament.start_time*1000).toLocaleString() }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Users registeres / max number of registrations">
                        <UsersIcon class="size-4" />
                    </Tooltip>
                    <span>{{ tournament.registered }}/{{tournament.max_participants }}</span>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <!-- <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" /> -->
                    {{ tournament.description }}
                </div>
                <router-link :to="{name: 'tournament-info', params:{tournament_id: tournament.tournament_id}}">
                    <button class="btn btn-blue h-10 w-full">Info</button>
                </router-link>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>


