<script setup>
import { useRoute, useRouter } from 'vue-router'

import Login from './Login.vue'
import Register from './Register.vue'
import Header from './Header.vue'

const router = useRouter();
</script>

<template>
    <Header />
<div class="container mx-auto h-full px-2 md:px-0 flex-col flex py-[10vh] sm:py-[25vh] gap-y-36">
    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-auto">
            <h2 class="text-xl md:text-4xl">Improve your game</h2>
            <div class="flex items-center justify-start gap-x-2">
                <img class="h-6 aspect-square md:h-16" src="@/assets/images/logo.png">
                <h1 class="text-2xl text-middle md:text-5xl relative">
                    OpenGammon.com
                </h1>
            </div>
            <div class="flex justify-center gap-x-4 mt-4 h-12">
                <Login 
                    @logged-in="router.replace({name:'home'})">;
                </Login>
                <Register
                    @registered=""
                ></Register>
                <!-- 
                <button class="btn btn-blue text-2xl w-1/3 h-12">
                    Login 
                </button>
                <button class="btn btn-blue text-2xl w-1/3">
                    Register
                </button>
                -->
            </div>
            <div class="mt-8 md:mt-12">
                <h2 class="md:text-4xl font-thin">Questions? Read our 
                    <a href="/faq/" class="underline pointer">
                        FAQ
                    </a>
                </h2>
            </div>
        </div>
        <div class="w-full sm:w-1/3">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_game.png?preset=thumbnail&srcset" />
        </div> 
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_board.png?preset=thumbnail&srcset">
        </div>
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Play Online on the #1 Backgammon Site</h2>
            <router-link :to="{name:'home'}">
            </router-link>
            <p class="text-xl md:text-xl">
                To be the best, you need to compete against the best. On OpenGammon
                you can enjoy pure backgammon, no frills, and no strings attached.
                Play a match against other people from long matches with tournament
                time controls to fast paced bullet DMP games, we have it all.
            </p>
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Beat our Bots</h2>
            <p class="text-xl md:text-xl">
                Are you starting out? Or do you want a good challenge? We have an
                extensive roster of opponents to hone your skills. We keep track
                of your progression against each bot. Playing a bot
                does not change your rating, so it is a good warm up for one of
                our live matches.
            </p>
        </div>
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_bots.png?preset=thumbnail&srcset">
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_customboard.png?preset=thumbnail&srcset">
        </div>
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Customize your board</h2>
            <p class="text-xl md:text-xl">
                Is green and red getting old? Do you want to play clockwise? 
                You can customize your playing field to suit your taste and needs.
                You can also submit your design to be included in the community
                board registry.
            </p>
            <router-link :to="{name:'color-profiles'}">
                <div class="flex justify-start h-12 my-4">
                    <button class="btn btn-blue md:text-2xl">
                Browse our Community Boards
                    </button>
                </div>
            </router-link>
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Solve Backgammon Puzzles</h2>
                <router-link :to="{name:'puzzle', params:{'puzzle_id': 'random'}}">
                <div class="flex justify-start h-12 my-4">
                    <button class="btn btn-blue md:text-2xl">
                        Solve Puzzles
                    </button>
                </div>
                </router-link>
            <p class="text-xl md:text-xl">
                Puzzles are a tried and true training tool that will help you
                become a better player. We create the puzzles from errors made 
                in the matches on our platform. The higher your puzzle ranking the harder 
                the puzzles become. Do you have what it takes to become the
                top puzzler?
            </p>
        </div>
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_puzzles.png?preset=thumbnail&srcset">
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_analysis.png?preset=thumbnail&srcset">
        </div>
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Analyse your Matches</h2>
            <router-link :to="{name:'home'}">
            </router-link>
            <p class="text-xl md:text-xl">
                Use our easy to use analyser to analyse your games. Our analysis
                server analyses your games with a world class engine. Tricky
                position? You can increase the analysis level per position.
            </p>
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">Compete in our Tournaments</h2>
            <router-link :to="{name:'home'}">
            </router-link>
            <p class="text-xl md:text-xl">
                Compete against other players in our tournaments. We feature
                Sit and Go tournaments. You can join in on the fun for one or
                two matches or try your luck and compete for the top place. 
            </p>
        </div>
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_tournament.png?preset=thumbnail&srcset">
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_statistics.png?preset=thumbnail&srcset">
        </div>
        <div class="w-auto sm:w-1/2">
            <h2 class="text-4xl">View your statistics</h2>
            <router-link :to="{name:'home'}">
            </router-link>
            <p class="text-xl md:text-xl">
                We provide you with useful statistics about your play so you
                know where to focus your training. You can view your and your
                rival's statistics to see how you stack up against the rest.
            </p>
        </div>
    </div>

    <div class="flex flex-col items-center justify-center gap-x-8 gap-y-4 sm:flex-row">
        <div class="sm:w-1/2 w-auto">
            <h2 class="text-4xl">Climb the leaderboards</h2>
            <router-link :to="{name:'home'}">
            </router-link>
            <p class="text-xl md:text-xl">
                Our improved rating system takes both your level of play as
                well as your wins into account. Compete with the best on our
                platform and rise to the top.
            </p>
        </div>
        <div class="w-full sm:w-1/3 order-last sm:order-none">
            <img class="w-full" srcset="@/assets/images/screenshots/screenshot_leaderboard.png?preset=thumbnail&srcset">
        </div>
    </div>

</div>
</template>

<style scoped>
</style>
