<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UserCircleIcon, ClockIcon, ShareIcon, TrashIcon} from '@heroicons/vue/24/outline'

import Header from './Header.vue'
import Board from './Board.vue'

import { Match } from '../assets/js/match.js'
import {BoardState} from '../assets/js/board.js'
import {StateMachine } from '../assets/js/statemachine.js'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore, useMatchSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();
const matchStore = useMatchSSEStore();

const route = useRoute();
const router = useRouter();

let update_analysis_timeout_id = null;

const state = reactive({
    current_board: new BoardState(),
    match_info: {},
    clock_data: {},
});

const extra_data = reactive({
    changed: false,
    import_export: false,
    show_store:false,
    show_section:"featured",
    my_themes: [],
    featured_themes: [],
    shared_theme: null,
    theme_name: "",
    advanced: false,
});

const color_preferences = reactive({
});

onMounted(async () => {
    await userStore.loadUser();
    await userStore.loadPreferences();
    // await userStore.resetPreferences();
    Object.assign(color_preferences, userStore.preferences.board.color);
    console.log("PREFS", color_preferences);

    const board_id = "editor";
    const board_element = document.getElementById("board-"+board_id);
    userStore.applyBoardPreferences(board_element, color_preferences);

    if(route.query.theme_id){
        get_theme(route.query.theme_id).then( (theme) => {
            if(theme == null){
                messageStore.alertUser("Failed to load theme", "The share url is invalid.");
                return;
            }
            console.log("SHARED", theme);
            extra_data.shared_theme = theme;
            extra_data.show_section = "shared";
            Object.assign(color_preferences, theme.theme_colors);
            userStore.applyBoardPreferences(board_element, color_preferences);
        });
    }

    get_themes(true).then( (themes) => {
        extra_data.featured_themes = themes;
    });
    get_themes(false).then( (themes) => {
        extra_data.my_themes = themes;
    });
});

onUnmounted(() => {
});

const board_colors = {
    "Point":{
        "Dark": "point_b",
        "Dark First": "point_b_first",
        "Light": "point_w",
        "Light First": "point_w_first",
    },
    "Stone":{
        "Dark": "stone_b",
        "Dark Border": "stone_border_b",
        "Light": "stone_w",
        "Light Border": "stone_border_w",
    },
    "Board":{
        "Field": "field",
        "Case": "case",
        "Text": "board_text",
        "Highlight": "highlight",
        "Button": "button",
        "Button Text": "button_text",
        "Button Border": "button_border",
    },
    "Dice":{
        "Dark Background": "dice_b",
        "Dark Pips": "dice_pip_b",
        "Dark Border": "dice_border_b",
        "Light Background": "dice_w",
        "Light Pips": "dice_pip_w",
        "Light Border": "dice_border_w",
    },
    "Cube":{
        "Background": "cube",
        "Number": "cube_pip",
        "Border": "cube_border",
    }
};

function current_themes(){
    if(extra_data.show_section == "my"){
        return extra_data.my_themes;
    }else if(extra_data.show_section == "featured"){
        return extra_data.featured_themes;
    }else if(extra_data.show_section == "shared"){
        return [extra_data.shared_theme];
    }
}

function update_editor(){
    extra_data.changed = true;
    console.log(color_preferences);

    const board_id = "editor";
    const board_element = document.getElementById("board-"+board_id);
    userStore.applyBoardPreferences(board_element, color_preferences);
}

function save_theme(){
    for(let preference_key in color_preferences){
        userStore.updatePreference(
        'board', `color.${preference_key}`, color_preferences[preference_key])
    }
    extra_data.changed = false;
    userStore.savePreferences();
}

function reset_theme(){
    console.log(userStore.defaultPreferences.board.color);
    Object.assign(color_preferences, userStore.defaultPreferences.board.color);
}

async function get_themes(featured){
    let url = "";
    if(featured){
        url = "/theme/";
    }else{
        url = "/theme/user/";
    }
    const response = await fetch(app_server + url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    console.log("Themes", data);
    return data.themes
}

async function get_theme(theme_id){
    const response = await fetch(app_server + `/theme/${theme_id}/`,{
        method: "GET",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();

    if(data.status != "success"){
        return null;
    }

    console.log(data);
    return {...data.theme};
}

async function store_theme(){
    const response = await fetch(app_server + "/theme/",{
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body:JSON.stringify({
            name: extra_data.theme_name,
            colors: color_preferences,
        }),
    });

    const data = await response.json();
    if(data.status == "success"){
        messageStore.alertUser("Saved Theme", "Theme succesfully saved to your account.");
    }else{
        messageStore.alertUser("Error Saving", data.message);
    }
    console.log("Stored", data);

    get_themes(false).then( (d) => {
        extra_data.my_themes = d;
    });
}

async function delete_theme(theme_id){
    const response = await fetch(app_server + `/theme/${theme_id}/`,{
        method: "DELETE",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    get_themes(false).then( (d) => {
        extra_data.my_themes = d;
    });

    console.log(data);
}

async function share_theme(theme_id){
    if(window.isSecureContext){
        const url = window.location.href + `?theme_id=${theme_id}`;
        await navigator.clipboard.writeText(url);
        messageStore.alertUser("Share", "Copied the share URL to the clipboard.");
    }
}

function select_theme(theme){
    Object.assign(color_preferences, theme.theme_colors);
    for(var key in color_preferences){
        if(theme.theme_colors[key] == null){
            color_preferences[key] = null;
        }
    }
    console.log("SELECT", color_preferences);
    update_editor();
    save_theme();
}


const board_states = reactive({});

function get_board_state(board_id){
    const state = board_states[board_id] || '11cccghhijjkk:446688dddddlm:N0N:51:W:R:4:2:9:4';
    board_states[board_id] = state;

    return state;
}
function get_board_color(board_id){
    const state = get_board_state(board_id);
    const board = new BoardState(state);

    return board.opponent[board.color];
}

function handle_move(board_id, positionString, action){
    const state_machine = new StateMachine();
    const state = new BoardState(get_board_state(board_id));
    console.log(state);

    state_machine.player_color = state.opponent[state.color];
    state_machine.roll_dice_callback = () => [];

    var solution = null;
    var new_board = state_machine.next_state(new BoardState(positionString), action);

    if(new_board.game_state == "PR"){
        new_board.game_state = "R";
        new_board.dice = [Math.floor(Math.random() * 6)+1, Math.floor(Math.random() * 6)+1];
        new_board.color = state.color;
    }
    board_states[board_id] = new_board.toPositionString();
    console.log("NEW:", board_states[board_id], board_id);
}

</script>
<template>
<Header />
<div class="h-rest flex flex-col md:flex-row-reverse">
<div class="grow order-first w-full md:w-2/3 md:order-last">
    <Board 
       :boardID= "'editor'"
       :positionString="get_board_state('editor')" 
       :player_color="get_board_color('editor')" 
       :clock_data="state.clock_data"
       :board_message="state.board_message"
       :match_info="state.match_info"
       :show_chat="true"
       :show_resign="true"
       :show_pip_numbers="true"
       :direction="'CW'"
       :can_do_actions="true"
       :theme="color_preferences"
         @move-end="(x, y) => handle_move('editor', x, y)"
    >
    </Board>
</div>

<div class="md:w-1/3 px-2 flex flex-col gap-y-4 overflow-y-auto">
    <h1 class="text-2xl font-bold hover:text-field-color cursor-pointer" 
        @click="extra_data.show_section='featured'"
        :class="{'text-field-color': extra_data.show_section=='featured'}"
    >
        Featured Themes
    </h1>
    <h1 class="text-2xl font-bold hover:text-field-color cursor-pointer"
        @click="extra_data.show_section='my'"
        :class="{'text-field-color': extra_data.show_section=='my'}"
    >
        My Themes
    </h1>
    <h1 class="text-2xl font-bold hover:text-field-color cursor-pointer"
        @click="extra_data.show_section='shared'"
        :class="{'text-field-color': extra_data.show_section=='shared'}"
        v-if="extra_data.shared_theme"
    >
        Shared Theme
    </h1>
    <h1 class="text-2xl font-bold hover:text-field-color cursor-pointer"
        @click="extra_data.show_section='builder'"
        :class="{'text-field-color': extra_data.show_section=='builder'}"
    >
        Theme Builder
    </h1>
<div v-if="extra_data.show_section == 'builder'">
<span class="flex justify-between">
<h2 class="text-xl">Board Colors</h2>
<span class="flex gap-x-2">
    <button class="btn btn-blue" @click="reset_theme()">
       Reset 
    </button>
    <button class="btn btn-blue" @click="save_theme()" :disabled="!extra_data.changed">
        Set Theme
    </button>
</span>
</span>
<span class="" v-if="userStore.preferences.board"
    v-for="settings, category in board_colors"
>
    <h3 class="text-lg font-bold">{{ category }}</h3>
    <div v-for="setting, name in settings"
        class="flex flex-col gap-y-2 w-64"
    >
        <span class="flex justify-between">
            <label>{{ name }}</label>
            <span class="flex items-center">
            <input type="color" v-model="color_preferences[setting]"
                @change="() => update_editor()" />
            <input type="text" v-model="color_preferences[setting]"
                @change="() => update_editor()" 
                class="inline-block text-xs py-0 w-20 font-mono  "
                v-if="extra_data.advanced"
            />
            </span>
        </span>
    </div>
</span>
<button class="btn btn-blue"
    @click="extra_data.advanced = extra_data.advanced ^ true">
    Advanced mode
</button>

<div class="my-2">
    <button class="btn btn-blue"
        @click="extra_data.show_store = extra_data.show_store ^ true">
        Store Theme
    </button>
    <div v-if="extra_data.show_store" class="h-10">
        <input class="h-full border-b-1" type="text" placeholder="Theme name"
         v-model="extra_data.theme_name">
         <button class="btn btn-blue h-full" 
             :disabled="extra_data.theme_name.length < 3"
             @click="store_theme()"
         >Store</button>
    </div>
</div>

<div class="my-2">
    <button class="btn btn-blue"
        @click="extra_data.show_importexport = extra_data.show_importexport ^ true">
        Import/Export
    </button>
    <div v-show="extra_data.show_importexport">
        <textarea 
            class="w-full h-96"
            @change="(e) => {Object.assign(color_preferences, JSON.parse(e.target.value)); update_editor();}"
        >{{ JSON.stringify(color_preferences, null, 2) }}</textarea>
    </div>
</div>
</div>
<!-- THEME Picker-->
<div class="flex flex-col gap-y-4 h-full overflow-y-auto"
    v-if="['shared', 'my', 'featured'].includes(extra_data.show_section)">
    <div v-for="theme in current_themes()"
        class="flex flex-col"
    >
        <div class="w-full mb-4"
            @click.stop="select_theme(theme)"
        >
            <Board 
                :boardID="theme.theme_id"
                :player_color="'W'"
                :positionString="'11cccghhijjjjkk:44666688dddddlm:N0N:51:W:R:4:2:9:4'" 
                :can_do_actions="true"
                :theme="theme.theme_colors"
            />
        </div>
        <div  
            class="flex justify-between items-center gap-x-6 -mt-3 opacity-70 hover:opacity-100 transition-all">
            <div class="flex gap-x-1 items-center">
                <UserCircleIcon class="w-6 h-6" /> 
                <span>{{ theme.author_info.username }}</span>
            </div>
            <div class="flex gap-x-1 items-center hidden">
                <ClockIcon class="w-6 h-6" /> 
                <span>{{ new Date(theme.create_time*1000).toDateString() }}</span>
            </div>
            <div>
                <span>{{ theme.name }}</span>
            </div>
            <div class="flex gap-x-3">
                <ShareIcon
                    class="w-6 h-6 hover:text-stone-b-color cursor-pointer"
                    @click="share_theme(theme.theme_id)"
                />
                <TrashIcon v-if="theme.author_id == userStore.info.user_id"
                    class="w-6 h-6 hover:text-stone-b-color cursor-pointer"
                    @click="delete_theme(theme.theme_id)"
                />
            </div>
        </div>
    </div>
</div>
</div>
</div>
</template>
