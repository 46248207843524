<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {BookOpenIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    courses: [],
});


onMounted(() => {
    get_courses();
});

watch( () => route.query, (new_query, old_query) =>{
    get_courses();
});

async function get_courses(){
    var url = app_server + `/lesson/course/`;
    if(router.query){
        const params = new URLSearchParams(router.query);
        url += params.toString();
    }

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    if(data.status == "success"){
        extra_data.courses = data.courses;
    }
    
    console.log(data);
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12">
        Your Courses
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color py-4 px-8 group relative transition-all rounded-lg" 
            v-for="course in extra_data.courses">
            <div class="grow">
                <router-link :to="{name: 'course', params:{slug: course.slug}}">
                    <h2 class="text-4xl mb-8 tracking-wide capitalize hover:text-accent-3-color">
                        {{ course.title }}
                    </h2>
                </router-link>
                <div class="flex gap-x-4 mt-3 items-center font-light">
                    <div class="grow">
                        <div class="flex gap-x-1 items-center">
                            <BookOpenIcon class="w-4 h-4" />
                            <span class="text-right">{{ course.lessons.length }}</span>
                        </div>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(course.publish_time*1000).toDateString() }}</span>
                    </div>
                </div>
                <div class="mt-4 text-lg font-light overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ course.summary }}
                </div>
                <div class="flex justify-center gap-x-4">
                    <router-link :to="{name: 'course', params:{slug: course.slug}}">
                    <button class="btn btn-blue h-12">
                        Start Course
                    </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>



