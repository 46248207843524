<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {ArrowLeftIcon, BookOpenIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();
var slug;

const props = defineProps({
});

const course_data = reactive({
});

const lessons_data = reactive({
});


const placeholder_lesson_data = {
    title: "Loading...",
    slug: "no",
    lesson_id: "hihi",
    author: {username: "Piraat"},
    published_time: 0,
    summary: "We are still waiting for this lesson to load! Must be really hard!",
    questions: [],
    user: {},
};

var course_slug;
onMounted(async () => {
    course_slug = route.params.slug;
    await get_course(course_slug);
});

async function get_course(slug){
    const response = await fetch(app_server + `/lesson/course/slug/${ slug }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },

    });
    const data = await response.json();
    Object.assign(course_data, data.course);

    console.log(data);
    for(var lesson_id of data.course.lessons){
        lessons_data[lesson_id] = Object.assign({}, placeholder_lesson_data);
    }

    await Promise.allSettled(data.course.lessons.map( x => get_lesson(x)) );

    console.log(course_data.lessons);
    for(var lesson_id of course_data.lessons){
        Object.assign(lessons_data[lesson_id], {can_start: can_start_lesson(lesson_id)});
        console.log(lessons_data[lesson_id].can_start);
    }
    console.log(lessons_data);

    return data.course;
}

async function get_lesson(lesson_id){
    const response = await fetch(app_server + `/lesson/${ lesson_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    Object.assign(lessons_data[lesson_id], data.lesson);
    console.log(data.lesson);
}

function get_lesson_data(lesson_id){
    if(lessons_data[lesson_id] != null){
        return lessons_data[lesson_id];
    }else{
        return placeholder_lesson_data;
    }
}

function can_start_lesson(lesson_id){
    if(course_data.lessons == null){
        return false;
    }
    const index = course_data.lessons.findIndex((x) => x == lesson_id);

    if(index < 0){
        return false;
    }else if(index == 0){
        console.log("THE FIRST", lesson_id);
        return true;
    }

    const previous_lesson_id = course_data.lessons[index-1];
    if(lessons_data[previous_lesson_id] == null){
        console.log("NO previous");
        return false;
    }
    const stars = lessons_data[previous_lesson_id].user.stars;


    return stars >= 2
}
function can_edit(lesson){
    const user_id = userStore.info.user_id;
    return lesson.author_id == user_id || lesson.editors.includes(user_id);
}
</script>

<template>
    <Header />
    <div class="container mx-auto">
        <h1 class="text-4xl flex items-center justify-center gap-x-12 font-semibold text-center mt-8 mb-8">
            <router-link :to="{name: 'course-list'}" class="text-2xl font-thin">
                <ArrowLeftIcon class="size-8 hover:text-accent-3-color" />
            </router-link><!--- The right margin is for centering -->
            <span class="mr-20 text-4xl tracking-wide capitalize">
                {{ course_data.title }}
            </span>
        </h1>
        <div class="text-center text-xl font-thin mb-12 w-prose">
            {{ course_data.summary }}
        </div>

    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color py-4 px-6 group relative transition-all" 
            v-for="lesson_id in course_data.lessons">
            <div class="grow"
                 :set="lesson = lessons_data[lesson_id]"
            >
                <span class="flex gap-x-4 items-center">
                    <h2 class="text-2xl">
                        {{ lesson.title }}
                    </h2>
                    <span class="flex justify-center">
                        <svg v-for="i in Array((lesson.user != null) ? lesson.user.stars : 0).keys()"
                             class="w-4 h-4 text-yellow-300 stroke-case-color stroke-2 ms-1" 
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                             fill="currentColor" viewBox="0 0 22 20">
                             <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                    </span>
                </span>
                <div class="flex gap-x-4 mt-3">
                    <div class="flex gap-x-1 items-center">
                        <BookOpenIcon class="w-4 h-4" />
                        <span class="text-right">{{ lesson.questions.length }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <UserCircleIcon class="w-4 h-4" />
                        <span class="text-right">{{ lesson.author.username }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(lesson.publish_time*1000).toDateString() }}</span>
                    </div>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ lesson.summary }}
                </div>
                <div class="flex justify-center items-center gap-x-4">
                    <router-link :to="{name: 'lesson', params:{slug: lesson.slug}, query:{course: course_data.slug}}">
                    <button class="btn btn-blue h-12"
                        :disabled="!lessons_data[lesson_id].can_start"
                    >
                    Start Lesson
                    </button>
                    </router-link>
                    <router-link v-if="can_edit(lesson)"
                        :to="{name: 'lesson-create', params:{lesson_id: lesson.lesson_id}}">
                        <button class="btn btn-yellow">
                            Edit
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>



