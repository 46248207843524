<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {FlagIcon} from '@heroicons/vue/24/solid'

import ToolTip from './Tooltip.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const props = defineProps({
    object_id: {
        type: String,
    },
    object_type: {
        type: String,
    },
})

const data = reactive({
    reason: "",
    description: "",
    open_modal: false,
});

const emit = defineEmits([
    'report-submitted',
]);

const reasons = {
    "user": [
        "Behaviour",
        "Cheating",
        "Sandbagging/Smurfing/Boosting",
        "Running Clock",
        "Other",
    ],
    "match": [
        "Disconnected",
        "Lagging",
        "Cheating",
        "Sandbagging/Smurfing/Boosting",
        "Illegal moves",
        "Rigged Dice",
        "Other",
    ],
    "puzzle": [
        "Incorrect",
        "Too easy",
        "Other",
    ],
}

onMounted(() => {

});

async function submit_report(){
    const report_data = {
        object_id: props.object_id, 
        object_type: props.object_type, 
        reason: data.reason,
        description: data.description,
    };
     
    const response = await fetch(app_server + "/report/?days=90", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(report_data),
    });
    
    const match_data = await response.json();
    messageStore.alertUser("Report Submitted", "Your report has successfully submitted.");
    data.reason = "";
    data.description = "";
    data.open_modal = false;
    
    emit("report-submitted");
}

</script>
<template>
    <div
        class="absolute top-1 right-1" 
    >
        <ToolTip :text="'Open the report menu'">
            <FlagIcon 
                @click="data.open_modal=true"
                class="size-4 text-main-5-color opacity-80 cursor-pointer hover:opacity-100"
            />
        </ToolTip>

        <Teleport to="body">
        <TransitionRoot as="template" :show="data.open_modal">
        <Dialog as="div" class="relative z-10" @close="data.open_modal = false">
          <TransitionChild as="template" enter="ease-out duration-300" 
                enter-from="opacity-0" enter-to="opacity-100" 
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-main-1-color opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-main-2-color px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <h1 class="text-xl font-semibold pt-2">
                Submit a report for this {{ props.object_type }}
            </h1>
            <label for="initialstate" class="block text-sm  pt-4 font-medium leading-6">
                Reason 
            </label>
            <select v-model="data.reason" id="reason"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300"
            >
                <option v-for="reason in reasons[props.object_type]" 
                        :value="reason"
                >
                    {{ reason }}
                </option>
            </select>
            <label for="Description" class="block text-sm pt-4 font-medium leading-6">
                Extra Information 
            </label>
            <textarea v-model="data.description"
                placeholder="Extra information so we can solve the problem"
                class="w-full h-36"
            />
            <button class="btn btn-blue mt-2 w-full" @click="submit_report()">
                Submit Report 
            </button>
            <button class="btn btn-red mt-2 w-full" @click="data.open_modal=false">
                Cancel
            </button>
            
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
        </Teleport>
    </div>
</template>

<style scoped>
</style>
