<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    offset:0,
    status:"active",
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const lesson_list = reactive([]);
onMounted(() => {
    load_lesson_list();
});

async function load_lesson_list(){
    var url = app_server + "/admin/lesson/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    console.log(url);
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    lesson_list.length = 0;// empty the users list
    console.log(data);

    if(data.lessons){
        lesson_list.push(...data.lessons);
        //extra_data.total_nrof_users = data.total_nrof_users;
    }
    console.log(data);
}

async function remove_lesson(lesson_id){
    const response = await fetch(app_server + `/admin/lesson/${lesson_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_lesson_list();
}

function next_page(){
    extra_data.offset += lesson_list.length;
    load_lesson_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - lesson_list.length);
    load_lesson_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

    <router-link :to="{name: 'lesson-create'}">
        <button class="btn btn-blue">
            Create Lesson 
        </button>
    </router-link>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Title</th> 
        <th scope="col" class="sticky top-0 z-10">Author</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Updated</th> 
        <th scope="col" class="sticky top-0 z-10">Published</th> 
        <th scope="col" class="sticky top-0 z-10">Difficulty</th> 
        <th scope="col" class="sticky top-0 z-10"># Questions</th> 
        <th scope="col" class="sticky top-0 z-10">start/fin/stars</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="lesson_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Courses
    </td>
    </tr>
    <tr v-for="lesson, index in lesson_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'lesson-create', params: {lesson_id: lesson.lesson_id}}">
                {{ lesson.title }}
            </router-link>
        </td>
        <td class="text-left">{{ lesson.author.username }}</td>
        <td class="text-left">{{ lesson.status }}</td>
        <td class="text-left">{{ (new Date(lesson.update_time*1000)).toISOString().slice(0,10) }}</td>
        <td class="text-left">{{ (new Date(lesson.publish_time*1000)).toISOString().slice(0,10) }}</td>
        <td class="text-right">{{ lesson.difficulty }}</td>
        <td class="text-right">{{ lesson.questions.length }}</td>
        <td class="text-right">{{ lesson.started }}/{{ lesson.finished }}/{{lesson.stars}}</td>
        <td>
            <button class="btn" @click="remove_lesson(lesson.lesson_id)">Remove</button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>


