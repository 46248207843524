<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {ClockIcon, SparklesIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import {BoardState} from '../assets/js/board.js'

import Header from './Header.vue'
import Board from './Board.vue'
import Tooltip from './Tooltip.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
    rated: true,
});

const extra_data = reactive({
    comments: [],
    requests: [],
    category: "recent",
});


onMounted(() => {
    get_comments();
    get_comment_requests();
});

async function get_comments(){
    var url = app_server + `/comment/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const comment_data = await response.json();
    if(comment_data.status == "success"){
        extra_data.comments = comment_data.comments;
    }
    
    console.log(comment_data);
}

async function get_comment_requests(){
    var url = app_server + `/commentrequest/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const comment_data = await response.json();
    if(comment_data.status == "success"){
        extra_data.requests = comment_data.comment_requests;
    }
    
    console.log(comment_data);
}

function get_color(boardstate){
    const board = new BoardState(boardstate);

    return board.opponent[board.color];
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
        <h1 class="text-4xl font-semibold text-center mt-8 mb-12">
            Recent Comments
            <span v-if="extra_data.requests.length > 0"
                @click="extra_data.category='requests'"
                class="font-thin cursor-pointer hover:text-field-color"
            >
                ({{ extra_data.requests.length }} comment requests)
            </span>

        </h1>
    <!-- COMMENTS -->
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8"
        v-if="extra_data.category == 'recent'"
    >
        <div class="border-b-2 shadow-xl py-4 px-6 group relative transition-all" 
            v-for="comment in extra_data.comments">
            <router-link :to="{name: 'puzzle', params:{puzzle_id: comment.puzzle_id}}">
            <div class="flex gap-x-4 mt-3">
                <Tooltip class="absolute top-0 right-0" 
                    :text="'This is a helpful explanation'">
                    <SparklesIcon 
                        class="w-8 h-8 text-yellow-500" 
                        v-if="comment.helpful > 0"
                    />
                </Tooltip>
                <div class="flex gap-x-1 items-center">
                    <UserCircleIcon class="w-4 h-4" />
                    <span class="text-right">{{ comment.author_data.username }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <ClockIcon class="w-4 h-4" />
                    <span>{{ new Date(comment.create_time*1000).toDateString() }}</span>
                </div>
            </div>
            <div class="mt-4 text-lg font-thin relative">
                <Board 
                    :boardID="comment.comment_id"
                    :positionString="comment.puzzle.position"
                    :player_color="get_color(comment.puzzle.position)" 
                    :can_do_actions="false"
                    :show_away_default="true"
                />
            </div>
            <div class="font-thin flex gap-x-1 w-full mt-3" v-if="comment.puzzle.tags">
                <span class="rounded px-2 py-0.5 bg-field-light-color"
                    v-for="tag in comment.puzzle.tags">
                    {{ tag }}
                </span>
            </div>
            </router-link>
        </div>
    </div>
    <!-- REQUESTS -->
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8"
        v-if="extra_data.category == 'requests'"
    >
        <div class="border-b-2 shadow-xl py-4 px-6 group relative transition-all" 
            v-for="request in extra_data.requests">
            <router-link :to="{name: 'puzzle', params:{puzzle_id: request.puzzle_id}}">
            <div class="flex gap-x-4 mt-3">
                <div class="flex gap-x-1 items-center">
                    <UserCircleIcon class="w-4 h-4" />
                    <span class="text-right">{{ request.nrof_users }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <ClockIcon class="w-4 h-4" />
                    <span>{{ new Date(request.first_request_time*1000).toDateString() }}</span>
                </div>
            </div>
            <div class="mt-4 text-lg font-thin relative">
                <Board 
                    :boardID="request.puzzle.puzzle_id"
                    :positionString="request.puzzle.position"
                    :player_color="get_color(request.puzzle.position)" 
                    :can_do_actions="false"
                    :show_away_default="true"
                />
            </div>
            <div class="font-thin flex gap-x-1 w-full mt-3" v-if="request.puzzle.tags">
                <span class="rounded px-2 py-0.5 bg-field-light-color"
                    v-for="tag in request.puzzle.tags">
                    {{ tag }}
                </span>
            </div>
            </router-link>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>

