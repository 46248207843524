import {getPositionAnalysis, find_engine_move, get_random_move} from './bot_helper.js'

function sample_gaussian(mean=0, stdev=1) {
    const u = 1 - Math.random(); // Converting [0,1) to (0,1]
    const v = Math.random();
    const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    // Transform to the desired mean and standard deviation:

    const sample = z * stdev + mean;

    return sample;
}

export class GnuBot{
    name = "Gnu 1-ply";
    elo = 1000;
    match_length = 1;
    engine_id = "gnubg";
    analysis_config = "1ply";
    analysis_data = {};

    app_server_url = "";

    move_dict = {};
    tags = {};

    config = {
        checker:{
            error_rate: 0,
            initial_error_bank: 0.0,
            error_mean: 0.0,
            error_stdev: 0.0
        },
        cube:{
            error_rate: 0,
            initial_error_bank: 0.0,
            error_mean: 0.00,
            error_stdev: 0.00
        },
        match:{
            speed: 1, // A speed multiplyer
        },
    };

    checker_equity_bank = 0;
    cube_equity_bank = 0;
    total_analysis_time = 0;

    last_move_id = 0;

    constructor(config){
        this.config = Object.assign(this.config, config);
        this.checker_equity_bank = this.config.checker.initial_error_bank;
        this.cube_equity_bank = this.config.cube.initial_error_bank;

        console.log("CONFIG", this.config);
    }
    
    handle_analysis(analysis_data){
        this.analysis_data[analysis_data.analysis_id] = analysis_data;
    }

    async get_analysis(board, retries=3){
        var new_board = board.copy();
        this.get_tags(board);

        // var analysis, analysis_id;
        var start = Date.now();

        const config= {
            "config": this.analysis_config,
            "engine_id": this.engine_id,
        };
        
        const analysis = await getPositionAnalysis(
            new_board.toPositionString(), config, this.app_server_url
        );
        
        const analysis_id = analysis.request.analysis_id;

        while(this.analysis_data[analysis_id] == null){
            await new Promise(r => setTimeout(r, 50)); 
            // console.log(this.analysis_data[analysis_id]);

            if(Date.now() - start > 5000){
                retries -= 1;
                start = Date.now();
                console.log("Analysis timeout");
                break;
            }
        }

        const position_id = board.toPositionString();
        const analysis_data = this.analysis_data[analysis_id];

        if(analysis_data == null){
            if(retries <= 0){
                console.log("No analysis, reverting to martin");
                return {};
            }else{
                return await this.get_analysis(board, retries-1)
            }
        }

        analysis_data.tags = this.tags[position_id] || [];
        if(analysis_data.checker){
            this.total_analysis_time += analysis_data.checker.analysis_time || 0;
        }
        if(analysis_data.cube){
            this.total_analysis_time += analysis_data.cube.analysis_time || 0;
        }
        return analysis_data;
    }

    async get_tags(board){
        const position_id = board.toPositionString();
        
        const response = await fetch(this.app_server_url + `/position/${ position_id }/classify/`, {
            method: "GET",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        const data = await response.json();

        this.tags[position_id] = data.tags;

        return data.tags;
    }

    move_to_action(board, move){
        if(move.action != null){
            return move.action;
        }

        var new_board = board.copy();
        const player_color = board.opponent[board.color];

        for(const move_part of move.data){
            var from = move_part[0];
            var to = move_part[1] == 0 ? -1 : move_part[1];

            if(player_color == "W"){
                from = 25 - from;
                to = 25 - to;
            }
            if(to >= 25 || to <= 0){
                to = -1;
            }
            //console.log(move, from, to);
            new_board = new_board.moveStoneAbs(from, to, player_color);
        }
        
        return new_board;
    }
    
    async find_move(board){
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            return this.get_checker_move(board);
        } else if(board.game_state == "D"){
            return this.get_take_pass_move(board);
        } else if(board.game_state == "C"){
            return this.get_roll_double_move(board);
        }
    }

    get_error_limit(move_type="checker", analysis=null){
        /*
        Here we use the normal distribution and cap the bottom end at 0. This means
        that is the mean is close to 0 we will usually take the best move (even
        if the stdev is large-ish). This should lead to a more human-like behaviour
        */
        var error_bound = sample_gaussian(
            parseFloat(this.config[move_type]["error_mean"]), 
            parseFloat(this.config[move_type]["error_stdev"])
        );

        var equity_bank = 0;
        if(move_type == "checker"){
            equity_bank = this.checker_equity_bank;
        }else{
            equity_bank = this.cube_equity_bank
        }
        
        error_bound = Math.max(0.005, error_bound)
        error_bound = Math.min(equity_bank, error_bound);

        if(analysis != null){
            const strong_tags = new Set(this.config.checker.strong || []);
            const weak_tags = new Set(this.config.checker.weak || []);
            const tags = new Set(analysis.tags);

            if(strong_tags.intersection(tags).size > 0){
                error_bound /= (this.config[move_type].strong_factor || 2);
                error_bound -= (this.config[move_type].strong_flat || 0.02);
                error_bound = Math.max(0, error_bound);
            }
            if(weak_tags.intersection(tags).size > 0){
                const factor = (weak_tags.intersection(tags).size + 1) * (this.config[move_type].strong_factor || 1);
                error_bound *= factor;
                error_bound += (this.config[move_type].weak_flat || 0.02);
            }
        }

        return error_bound;
    }

    async get_checker_moves(state, analysis){
        var moves = [];

        if(analysis && analysis.checker){
            moves = analysis.checker.moves;
        }

        var forced = false;
        if(moves.length == 0){
            // We  either cannot move or GNU is being annoying...
            moves = [{ move:{action: get_random_move(state)}, EQ:0, forced: true}];
        }
        else if(moves.length <= 1){
            forced = true 
        }

        // We first compute the relative errors (distance from the best move)
        const best_move = moves[0];
        const best_move_eq = best_move.EQ || 0;

        // only consider the moves that are analysed with the same ply
        moves = moves.filter((x) => (x.ply || 0) == (best_move.ply || 0));
        for(let move of moves){
            move.EQ = Math.abs(best_move_eq - (move.EQ || 0));
        }

        return moves;
    }

    get_random_index(nrof_states){
        /*
         * We don't want a uniform distribution, but slightly skewed so more like:
         *
         * 0 1 1 1 2 2 2 3 3 4 4 5 5 6 7 8 9 10
         */
        var indices = [0, 1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 5, 5, 6, 7, 8, 9 , 10];

        if( nrof_states > 10){ // just use an uniform distribution
            return Math.floor(Math.random() * nrof_states);
        }

        indices = indices.filter( (x) => x < nrof_states );
        const index = Math.floor(Math.random() * indices.length);

        return indices[index]
    }

    async get_checker_move(state){
        const analysis = await this.get_analysis(state);
        var moves = await this.get_checker_moves(state, analysis)

        var error_bound = this.get_error_limit("checker", analysis);
        
        moves = moves.filter( (x) => (x.EQ || 0) <= error_bound);
        const max_error = Math.max( ...moves.map( (x) => (x.EQ || 0)), 0);

        // Instead of picking a uniform random index we pick a uniform random error
        // and then pick the first move that is above that error
        const rand_error = Math.random() * max_error;
        const chosen_move = moves.find( (x) => x.EQ >= rand_error);

        // const move_index =  this.get_random_index(moves.length);
        // const chosen_move = moves[move_index];

        if(chosen_move != null){
            this.checker_equity_bank -= (chosen_move.EQ || 0);
            this.checker_equity_bank = Math.max(0, this.checker_equity_bank);
        }
        this.checker_equity_bank += parseFloat(this.config.checker.error_rate) / 1000;

        // console.log("CHECKER", chosen_move.move.text, this.checker_equity_bank.toFixed(2), 
        //     chosen_move.EQ.toFixed(2), error_bound.toFixed(2));

        if(chosen_move == null){
            console.log(moves, error_bound, rand_error, max_error);
        }
        return this.move_to_action(state, chosen_move.move);
    }

    async get_take_pass_move(state){
        var analysis = await this.get_analysis(state);
        
        if(typeof analysis.cube == "object"){
            analysis = analysis.cube;
        }else{
            return "take";
        }

        const best = analysis["double"]["cube recommendation"]["best"];
        
        //first we set the action to the optimal play
        var action = "take";
        if(["EQ DT", "EQ ND"].includes(best)){
            action = "take";
        }
        else if( best == "EQ DP"){
            action = "pass";
        }
        else{
            console.log("Invalid move");
        }

        var recommendation = analysis["double"]["cube recommendation"]["actions"];
        recommendation = Object.fromEntries( recommendation.map( 
            ([k, _, v]) => [k, Math.abs(v)])
        );

        // compute how bad the suboptimal play is
        const take_eq = recommendation["EQ DT"] || 0;
        const pass_eq = recommendation["EQ ND"] || 0;

        const error_bound = this.get_error_limit("cube", analysis)

        if(take_eq <= error_bound){
            this.cube_equity_bank -= take_eq
            action = "take"
        }
        else if(pass_eq <= error_bound){
            this.cube_equity_bank -= pass_eq
            action = "pass"
        }

        this.cube_equity_bank += this.config.cube.error_rate / 1000;

        // console.log("CUBE TP", action, this.cube_equity_bank.toFixed(2), 
        //     take_eq.toFixed(2), pass_eq.toFixed(2), error_bound.toFixed(2));

        return action
    }

    async get_roll_double_move(state){
        var analysis = await this.get_analysis(state);
        
        if(typeof analysis.cube == "object" && analysis.cube.double != null){
            analysis = analysis.cube;
        }else{
            return "roll";
        }

        const best = analysis["double"]["cube recommendation"]["best"];

        var recommendation = analysis["double"]["cube recommendation"]["actions"];
        recommendation = Object.fromEntries( recommendation.map( 
            ([k, _, v]) => [k, Math.abs(v)])
        );

        const double_eq = Math.min(recommendation["EQ DT"] || 0, recommendation["EQ DP"] || 0);
        const roll_eq = recommendation["EQ ND"] || 0;
        
        var action = "roll";
        // First set action to the optimal play
        if(["EQ DT", "EQ DP"].includes(best)){
            action = "double"
        }
        else if(best == "EQ ND"){
            action = "roll";
        }

        // If a suboptimal play is within the error bound, do that.
        const error_bound = this.get_error_limit("cube", analysis);
        if(roll_eq <= error_bound){
            this.cube_equity_bank -= roll_eq;
            action = "roll";
        }
        else if(double_eq <= error_bound){
            this.cube_equity_bank -= double_eq;
            action = "double";
        }

        this.cube_equity_bank += this.config.cube.error_rate / 1000

        // console.log("CUBE RD", action, this.cube_equity_bank.toFixed(2), 
        //     roll_eq.toFixed(2), double_eq.toFixed(2), error_bound.toFixed(2));

        return action
    }
}
