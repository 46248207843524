<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {SparklesIcon, UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

const chart_options = {
    responsive: true,
    resizeDelay: 1000,
    animation: false,
    maintainAspectRatio: false,
    scales:{
        x:{
            ticks:{
                display: false,
            },
        },
    },
    plugins:{
        legend: {display: false},
    },
};

const extra_data = reactive({
});

onMounted(async () => {
    get_report();
});

async function get_report(){
    let params = {
        recompute: route.query.recompute,
        days: route.query.days,
    };
    console.log(params);
    params = Object.fromEntries(Object.entries(params).filter((x) => Boolean(x[1]) == true));
    const search_params = new URLSearchParams(params);
    const url = `/admin/usage/?${search_params.toString()}`

    console.log(url);

    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const report = await response.json();
    extra_data.reports = report.reports;
    
    console.log(report);
}

function to_readable_date(d){
    const month_nr_to_name = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Sep", "Oct", "Nov", "Dec"];
    return `${month_nr_to_name[d.getMonth()]}, ${d.getFullYear()}`
}

function get_chart_data(chart_type){
    if(extra_data.reports == null){
        return {
            datasets: []
        };
    }
    const getValue = (object, path) => path.split('.').reduce((o, k) => (o || {})[k], object);
    const style = getComputedStyle(document.body);
    const field_color = style.getPropertyValue('--field-color');
    let charts;
    
    if(Array.isArray(chart_type)){
        charts = chart_type.map(t => extra_data.reports.map(x => getValue(x, t)));
    }else{
        charts = [extra_data.reports.map( x => getValue(x, chart_type))];
    }
    const labels = extra_data.reports.map( x => x.date);
    
    const chart_data = {
        labels: labels,
        datasets: charts.map( (chart) => {
            return {
                label: chart_type,
                data: chart,
                borderColor: field_color,
            };
        }
        ),
    }

    return chart_data;
}

const graphs = [
    ["Active Users (30d)", "users.active.monthly"],
    ["Active Users (7d)", "users.active.weekly"],
    ["Active Users (1d)", "users.active.daily"],
    ["Retention", "users.retention"],
    ["Stickiness (30d)", "users.stickiness.monthly"],
    ["Stickiness (7d)", "users.stickiness.weekly"],
    ["Analysis count", "analysis.all.daily.count"],
    ["Analysis count (3ply)", "analysis.3ply.daily.count"],
    ["Analysis time", "analysis.all.daily.analysis_time"],
    ["Analysis time (3ply)", "analysis.3ply.daily.analysis_time"],
    ["Matches (all)", "matches.all.daily"],
    ["Matches (pvp)", "matches.pvp.daily"],
    ["Matches (bot)", "matches.bot.daily"],
    ["Matches (daily)", "matches.async.daily"],
    ["Puzzles User Rating won", "puzzles.rating_rated.daily.0"],
    ["Puzzles Puzzle Rating won", "puzzles.rating.daily.1"],
    ["Puzzles (rated)", "puzzles.rated.daily"],
    ["Puzzles (unrated)", "puzzles.unrated.daily"],
    ["Puzzle Rush", "puzzle_rush.daily"],
    ["Daily Puzzle", "puzzles.daily.daily.correct"],
    ["Daily Puzzle", [
        "puzzles.daily.daily.correct", 
        "puzzles.daily.daily.incorrect", 
        "puzzles.daily.daily.total"
    ]
    ],
    ["Tournament", "tournament.daily"],
]

</script>

<template>
    <div class="container mx-auto" :key="extra_data.user_id">

        <div class="p-4 rounded-md bg-case-light-color"
            v-for="graph in graphs"
        >
            <h3 class="text-lg font-medium">{{ graph[0] }}</h3>
            <div class="relative w-full h-full">
                <Line :data="get_chart_data(graph[1])" :options="chart_options" />
            </div>
            
        </div>
    </div>
</template>

<style>
</style>

