<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var bot_id;

const extra_data = reactive({
    bot: {}
});


onMounted(() => {
    bot_id = useRoute().params.bot_id;
    load_bot();
});

async function save_bot(){
    var url = app_server + `/admin/bot/${bot_id}/`;

    const data = Object.assign({}, extra_data.bot);
    data.config = JSON.parse(data.config);
    data.chat_config = JSON.parse(data.chat_config);

    console.log(JSON.stringify(data));

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const bot_data = await response.json();
    load_bot();
}

async function load_bot(){
    var url = app_server + `/admin/bot/${bot_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    if(data.bot){
        extra_data.bot = data.bot;
        extra_data.bot.config = JSON.stringify(data.bot.config, null, 4);
        extra_data.bot.chat_config = JSON.stringify(data.bot.chat_config, null, 4);
    }
}

async function upload_avatar(){
    var url = app_server + `/admin/bot/${bot_id}/avatar/`;

    const upload_input = document.getElementById('media-upload');
    const data = new FormData();
    data.append('file', upload_input.files[0])

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: data,
    });
    
    const media_data = await response.json();
    load_bot();
}
</script>

<template>
    <div class="container">
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-bots'}">
            Bot List
        </router-link>
    </button>
    <h1>Bot Detail</h1>
    <button @click="save_bot()" class="btn btn-blue">Save</button>
    <div class="">
        <h1>Avatar</h1>
        <img class="size-32 bg-main-2-color" 
            :src="'/media/' + extra_data.bot.avatar" />
        <div
            class="flex flex-col w-32 h-16 gap-y-2  "
        >
            <input type="file" id="media-upload" />
            <button class="btn btn-blue" @click="upload_avatar()">
                Upload
            </button>
        </div>
    </div>
    <form class="container flex flex-col max-w-prose mx-4">
        <div v-for="value,field in extra_data.bot">
            <label :for="field"
                   class="block text-sm font-medium leading-6"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.bot[field]"
                v-if="['description', 'config', 'chat_config'].includes(field)"
                :id="field"
                class="w-full"
            ></textarea>
            <input 
                v-model="extra_data.bot[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
    </div>
</template>

<style>
</style>
