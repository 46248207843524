<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const initial_states = [
    ["Backgammon", "11jjjjjhhhccccc:ooddddd88866666:N0N::W:IW:0:0"],
    // ["Last Roll", "cc:66:N0N::W:IW:0:0"],
    // ["End Game", "jjjkkkllmm:1223344455666go:N0N:W:IW:0:0"],
];

const match_length_options = [
    1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21,
];

const time_configs = [
    ["Normal (60|10)", "60|10|0"],
    ["Casual (120|30)", "120|30|0"],
    ["Speed (20|8)", "20|8|0"],
    ["Bullet (30|2)", "30|2|0"],
    ["Increment (5|1|3) (debug)", "5|1|3"],
    ["No Clock (debug)", "-1|0|0"],
];

const status_options = [
     "all", "active", "inactive", "finished", "cancelled",
]

const extra_data = reactive({
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
    status:"all",
});
const tournament_data = reactive({
    name: "",
    match_length: 1,
    timecontrol: "60|10|0",
    start_time: null,
    finish_time: null,
    recurrent: false,
})

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const tournament_list = reactive([]);
onMounted(() => {
    load_tournament_list();
});

async function load_tournament_list(){
    var url = app_server + "/admin/tournament/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    url += `?status=${extra_data.status}`
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    tournament_list.length = 0;// empty the users list
    if(data.tournaments){
        tournament_list.push(...data.tournaments);
    }
    console.log(data);
}

async function update_status(tournament_id){
    const response = await fetch(app_server + `/admin/tournament/${tournament_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const response_data = await response.json()
    
    console.log(response_data)
}

async function create_tournament(){
    tournament_data.start_time = new Date(tournament_data.start_time) / 1000;
    tournament_data.finish_time = new Date(tournament_data.finish_time) / 1000;
    
    console.log(tournament_data);
    
    const response = await fetch(app_server + "/admin/tournament/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(tournament_data),
    });
    
    const response_data = await response.json();

    extra_data.show_create = false;
    load_tournament_list();
}

async function remove_tournament(tournament_id){
    const response = await fetch(app_server + `/admin/tournament/${tournament_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_tournament_list();
}

function next_page(){
    extra_data.offset += 20;
    load_tournament_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_tournament_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<div class="pl-6" v-if="!extra_data.show_create">
<button class="btn btn-blue" @click="extra_data.show_create = true">
    Create Tournament
</button>
</div>
<div v-if="extra_data.show_create" class="w-96 pl-6">
    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
        Name
    </label>
    <input type="text" v-model="tournament_data.name">
    <label for="start" class="block text-sm font-medium leading-6 text-gray-900">
        Start
    </label>
    <input type="datetime-local" v-model="tournament_data.start_time">
    <label for="start" class="block text-sm font-medium leading-6 text-gray-900">
        Finish
    </label>
    <input type="datetime-local" v-model="tournament_data.finish_time">
    <label for="length" class="block text-sm font-medium leading-6 text-gray-900">
        Match length
    </label>
    <select v-model="tournament_data.match_length" id="length" 
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300" > 
        <option v-for="match_length in match_length_options" :value="match_length">
            {{ match_length }}
        </option>
    </select>
    <label for="timecontrol" class="block text-sm font-medium leading-6 text-gray-900">
        Time Control 
    </label>
    <select v-model="tournament_data.timecontrol" id="timecontrol"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
    >
        <option v-for="(state, index) in time_configs"
                :value="state[1]"
        >
            {{ state[0] }}
        </option>
    </select>
    <button class="btn btn-blue mt-2 w-full" @click="create_tournament()">
        Create Tournament 
    </button>
    <button class="btn btn-red mt-2 w-full" @click="extra_data.show_create=false">
        Cancel
    </button>
</div>

<select v-model="extra_data.status" @change="load_tournament_list">
    <option :value="v" v-for="v in status_options"> {{ v }}</option>
</select>

<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Name</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Start</th> 
        <th scope="col" class="sticky top-0 z-10">Finish</th> 
        <th scope="col" class="sticky top-0 z-10">Points</th> 
        <th scope="col" class="sticky top-0 z-10">Time Control</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="tournament_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Tournaments
    </td>
    </tr>
    <tr v-for="tournament, index in tournament_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-tournament-detail', params: {tournament_id: tournament.tournament_id}}">
                {{ tournament.name }}
            </router-link>
        </td>
        <td class="text-left">{{ tournament.status }}</td>
        <td class="text-left">{{ (new Date(tournament.start_time)).toLocaleString() }}</td>
        <td class="text-left">{{ (new Date(tournament.finish_time)).toLocaleString() }}</td>
        <td class="text-right">{{ tournament.points }}</td>
        <td class="text-right">{{ tournament.time_control }}</td>
        <td>
            <button class="btn" @click="remove_tournament(tournament.tournament_id)">Remove</button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>

