import {get_random_move, find_engine_move} from './bot_helper.js'

export class TobiasBot{
    name = "Tobias";
    elo = 600;
    match_length = 1;
    description = "Tobias really hates being behind in the race"

    engine_id = "gnubg";
    analysis_config = "1ply";
    analysis_data = {};

    app_server_url = "";
    
    handle_analysis(analysis_data){
        this.analysis_data = analysis_data;
    }
    
    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const pipcount = board.get_pipcount();
            if(pipcount.B - pipcount.W > pipcount.B*0.10 && Math.random() < 0.3){ 
                // Tobias is more than 10% behind in pips, start throwing
                return get_random_move(board);    
            }

            const config= {
                "config": this.analysis_config,
                "engine_id": this.engine_id,
            };
            return find_engine_move(this, board, config, this.app_server_url);

        }else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
    }
}
