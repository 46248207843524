<script setup>
import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'

import {ref, computed, watch, reactive, onMounted} from 'vue'
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/solid'

import { useRoute, useRouter } from 'vue-router'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();


const extra_data = reactive({
    state: null,
    content: "",
    loading: false,
    submitted: null,
});

const report_tokens = {
    "bug": "rt_Tk6BFo8xVuNtIFvqczPnJqDh",
    "feedback": "rt_8zGCZaBt7Na1IQBW39d2JHLW",
};
const urls = {
    "bug": "https://open.codecks.io/opengammon/decks/11-bugs",
    "feedback": "https://open.codecks.io/opengammon/decks/12-feedback",
};

const route = useRoute();
const router = useRouter();


async function post_to_codecks(){
    extra_data.loading = true;
    const token = report_tokens[extra_data.state];
    const response = await fetch(`https://api.codecks.io/user-report/v1/create-report?token=${token}`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            content: extra_data.content,
            severity: null,
            userEmail: userStore.info.email,
        }),
    });

    const data = await response.json();
    
    if(data.ok){
        messageStore.alertUser(
            "Feedback submitted", 
            "Thank you for taking the time to submit your feedback."
        );
        extra_data.submitted = extra_data.state;
        extra_data.state = null;
        extra_data.content = "";
    }

    extra_data.loading = false;
    console.log(data);
}

</script>
<template>
<Header />
<div class="container mx-auto mb-8 px-2 lg:px-0 lg:w-1/2">

<h1 class="text-4xl text-center font-bold my-16">
    Support
</h1>
<div class="text-xl font-light">
    If you run into problems or want to give some suggestions for improving OpenGammon
    you have come to the right place. Before asking for support please review our
    <a href="/faq/">FAQ</a>. To aggregate the feedback and bug reports we use 
    CoDecks. 
    <a href="https://open.codecks.io/opengammon">Click here</a> to open our ticket
    repository.
</div>

<div class="flex flex-col items-center gap-y-12 my-16">
    <div class="flex gap-x-8 items-center">
        <button class="h-14 w-36 btn btn-blue text-xl" 
            @click="extra_data.state='bug'"
            :disabled="extra_data.state == 'feedback'"
        >
            Report Bug
        </button>
        <button class="h-14 w-36 btn btn-blue text-xl" 
            @click="extra_data.state='feedback'"
            :disabled="extra_data.state == 'bug'"
        >
            Feedback
        </button>
    </div>
    <div class="flex flex-col justify-center w-full items-center gap-y-2"
         v-if="extra_data.state != null"
    >
        <textarea class=" w-full max-w-prose h-32" 
            placeholder="Please write a consise but precise report."
            v-model="extra_data.content"
        ></textarea>
        <ButtonLoad class="btn btn-blue h-12 w-1/2"
            :loading="extra_data.loading"
            @click="post_to_codecks()"
        >
            Submit
        </ButtonLoad>
        <button class="btn btn-red h-12 w-1/2"
                @click="extra_data.state = null"
        >
            Cancel 
        </button>
    </div>
    <div class="text-xl text-center"
        v-if="extra_data.submitted != null"
    >
        Thank you for submitting your feedback. You can check the status of your
        ticket in <a :href="urls[extra_data.submitted]">CoDecks</a>
    </div>


    <div class="font-light text-xl mt-12">
        If you have questions or want to talk with backgammon afficionados 
        you can also always reach out to the people in the
        <a href="https://discord.gg/t7G8UJPBTA">Backgammon Pub Discord</a>.
    </div>
    <div class="font-thin mt-32 text-center">
        Sometimes the old fashioned way works better. If the above options don't work,
        or you feel more comfortable with mail, you can send us an email to
        <a href="mailto:support@opengammon.com">our support mail address</a>
    </div>

</div>

</div>

</template>

<style scoped>

a{
    @apply hover:underline text-field-color;
}
</style>
