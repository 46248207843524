<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'
import { Match } from '../assets/js/match.js'

import Tooltip from './Tooltip.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;
const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
    ['show', 'tournament_id']
);

var counter_interval_id = null;
var start_time;
var heartbeat_timeout = 60;
var hints = [
    "Did you know you can draw arrows on the board using ctrl-click? You can remove them with the a button.",
    "Did you try Puzzle Rush? You can find it in the menu under Experimental.",
    "You can create your avatar using achievements you earn playing.",
    "Did you know you can conclude your move by hitting the space-bar? This can be a life saver for bullet.",
    "If you have comments or questions you can always send us an email.",
    "You can highlight points on the board by shift clicking them. You can remove the highlight by pressing 'a'.",
    "Ranking high in a tournament grants you Master Points (MP).",
    "By winning tournaments you can earn achievements.",
];

const extra_data = reactive({
    counter: 0,
    current_hint: 0,
    users_queued: 0,
    cancelled: false,
    joined: false,
    background: true,
});

onMounted(() => {
    sseStore.connect();
    matchStore.setup();
    
    document.addEventListener("visibilitychange", () => {
        extra_data.background = Document.hidden;
        
        if(extra_data.background){
            clearInterval(counter_interval_id);
        }else{
            start_counter();
        }
    });
});

onUnmounted( () => {
    // matchStore.cancel_tournament_join();
});

watch(props, async (newProps, oldProps) => {
});

function start_counter(){
    clearInterval(counter_interval_id);
    counter_interval_id = setInterval(() => {
        extra_data.counter = parseInt((Date.now() - start_time) / 1000);
    }, 1000);
}

</script>
<template>
    <div v-if="matchStore.current_queue != null">
        <span class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2 bg-accent-color text-light-color">
            <span> You are queueing for: </span>
            <span>{{ matchStore.current_matchqueue_info.name }}</span> 
            <span class="tabular-nums w-16 text-right">{{ matchStore.current_join_counter }}</span>
            <Tooltip :text="'Stop queueing'" :direction="'bottom'">
                <span class="sr-only">Reject</span>
                <XMarkIcon 
                    class="w-6 h-6 cursor-pointer"
                    @click="matchStore.cancel_arena_join()"
                />
            </Tooltip>
        </span>
    </div>
    <div v-if="matchStore.active_tournament != null">
        <span class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2 bg-accent-color text-light-color">
            <span> Waiting for your opponent to join </span>
            <span class="tabular-nums w-16 text-right">{{ matchStore.current_join_counter }}</span>
            <Tooltip :text="'Stop queueing'" :direction="'bottom'">
                <span class="sr-only">Reject</span>
                <XMarkIcon 
                    class="size-6 cursor-pointer"
                    @click="matchStore.cancel_play_tournament()"
                />
            </Tooltip>
        </span>
    </div>
</template>

<style scoped>
</style>
