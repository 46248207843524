<script setup>
import { FireIcon, FlagIcon, SparklesIcon, Bars3Icon,
    ChatBubbleLeftIcon, ChatBubbleLeftEllipsisIcon} from '@heroicons/vue/24/solid'

import Avatar from './Avatar.vue'
import UserName from './UserName.vue'
import Tooltip from './Tooltip.vue'
import ChatWindow from './ChatWindow.vue'

import {watch, ref, nextTick, computed, reactive, onMounted, onUnmounted} from 'vue'

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

import {useGameSettingStore} from '@/stores/gamesettingstore.js'
const gameSettingStore = useGameSettingStore();

var flash_timeout_id = null;

const props = defineProps({
    player_color: {
        type: String,
    },
    match_info: {
        type: Object,
    },
    score: {
        type: Object,
        default: null,
    },
    chat_data: {
        type: Object,
        default: {lobbies: []},
    },
    show_chat: {
        type: Boolean,
        default: true,
    }
});

const settings = reactive({
    sound: false,
    chat: true,
});

const extra_data = reactive({
    open: true,
    flash_chat: null,
    sidebar: 'sidebar',
});

const chat_data = reactive({
    message: {},
    show: null,
    status: "",
    lobbies: [],
    flex: false,
});

watch( () => chatStore.unread, () => {
    if(!gameSettingStore.settings.chat){
        return;
    }
    if(chatStore.unread == 0){
        return;
    }
    const user_ids = [props.match_info.white.user_id, props.match_info.black.user_id];
    const message = chatStore.last_message;

    if(message == null || !user_ids.includes(message.sender)){
        return;
    }
    extra_data.flash_chat = chatStore.last_message.message;
    clearTimeout(flash_timeout_id);
    flash_timeout_id = setTimeout( () => extra_data.flash_chat = null, 5000);
});

function get_user_info(opponent=false){
    if(Object.keys(props.match_info).length == 0){
        return {};
    }
    if(opponent){
        if(props.player_color == "W"){
            return props.match_info.black;
        }else{
            return props.match_info.white;
        }
    }else{
        if(props.player_color == "W"){
            return props.match_info.white;
        }else{
            return props.match_info.black;
        }
    }
    return {};
}

function get_coins_diff(opponent=false){
    // TODO fix this
    if(props.match_info.stake == null || props.score == null){
        return 0
    }

    const diffs = {
        "W": props.match_info.stake * (props.score.W - props.score.B),
        "B": props.match_info.stake * (props.score.B - props.score.W),
    };

    if( (props.player_color == "W")^opponent){
        return diffs["W"];
    }else{
        return diffs["B"];
    }


    return 0;
}

function toggleChat(){
    extra_data.flash_chat = null;
    if(chat_data.show == null){
        chat_data.show = true;
    }else{
        chat_data.show = !chat_data.show;
    }
    console.log("TOGGLE", chat_data.show, extra_data.flash_chat);
}

function toggleMenu(){
    if(extra_data.sidebar == 'sidebar'){
        extra_data.sidebar = 'menu';
    }else{
        extra_data.sidebar = 'sidebar';
    }
}

onMounted( () => {
});

</script>

<template>
<div class="sidebar h-full py-8
            w-[12em] lg:w-[20em] relative"
>
    <Bars3Icon class="w-8 h-8 top-3 right-3 absolute opacity-60
        hover:opacity-100 cursor-pointer text-board-text-color" 
        @click="toggleMenu()"
    />
    <div v-if="extra_data.sidebar == 'sidebar'" 
        class="flex flex-col justify-between h-full w-full">
         <div class="text-board-text-color text-center flex flex-col items-center">
            <span 
                v-if="Object.keys(get_user_info(true)).length > 0" 
                class="flex flex-col gap-y-2 w-full justify-center items-center"
            >
                <p class="truncate text-xl">
                    <UserName :user="get_user_info(true)"
                              :link_to_userview="false"
                    />
                </p>
                <div class="aspect-square w-1/3">
                    <Avatar :avatar_id="get_user_info(true).avatar"/>
                </div>
                <p v-if="props.match_info.points > 0" 
                    class='flex items-center justify-center'>
                    <FireIcon class="w-4 h-4"/> 
                    {{ get_user_info(true).rating.toFixed(0) }}
                </p>
                <p v-else class='flex items-center justify-center'>
                    <SparklesIcon class="w-4 h-4" /> 
                    {{ get_user_info(true).coins + get_coins_diff(true) }}
                </p>
            </span>
        </div>
        <div class="flex flex-col items-center justify-center">
            <button class="btn button mb-2 w-8 h-8 lg:w-16 lg:h-16 relative"  
                    :class="{'bg-point-b-color' : chatStore.unread == 0}" 
                    style="padding:0.25em" 
                    @click="() => {toggleChat()}"
                    @new-status="(status) => {
                        chat_data.new_message = status
                    }"
                    v-if="props.show_chat"
            >
                    
                <ChatBubbleLeftIcon v-if="!gameSettingStore.settings.chat"
                                    class="grayscale"/>
                <ChatBubbleLeftIcon v-else-if="chatStore.unread == 0"
                                    class=""/>
                <ChatBubbleLeftEllipsisIcon v-else
                                    class="text-highlight-color animate-pulse"/>
                <div v-if="extra_data.flash_chat != null" 
                    class="tooltip top bg-case-color text-board-text-color rounded-xl py-4 px-4 
                            w-[12em] font-normal text-left
                           relative">
                    <div class="overflow-hidden h-full w-full line-clamp-4">
                        {{ extra_data.flash_chat }}
                    </div>
                </div>
            </button>
        </div>        
        <slot></slot>
        <div class="text-board-text-color text-center flex flex-col items-center">
            <span 
                v-if="Object.keys(get_user_info(false)).length > 0" 
                class="flex flex-col gap-y-2 w-full justify-center items-center"
            >
                <p v-if="props.match_info.points > 0" 
                    class='flex items-center justify-center'>
                    <FireIcon class="w-4 h-4"/> 
                    {{ get_user_info(false).rating.toFixed(0) }}
                </p>
                <p v-else class='flex items-center justify-center'>
                    <SparklesIcon class="w-4 h-4" /> 
                    {{ get_user_info(false).coins + get_coins_diff(false) }}
                </p>
                <div class="aspect-square w-1/3">
                    <Avatar :avatar_id="get_user_info(false).avatar"/>
                </div>
                <p class="truncate text-xl">
                    <UserName :user="get_user_info(false)" 
                              :link_to_userview="false"
                    />
                </p>
            </span>
        </div>
    </div>
    <div v-else class="text-board-text-color px-4">
        <h1 class="text-xl font-semibold">
            Menu
        </h1>
        <table class="w-full mt-8">
            <tr v-for="val, key in gameSettingStore.settings"
                class="cursor-pointer"
                @click="gameSettingStore.settings[key] ^= true"
            >
                <td class="text-xl py-4 capitalize">
                    {{ key }}
                </td>
                <td class="w-10">
                    <span v-if="val">
                        On
                    </span>
                    <span v-else>
                        Off
                    </span>
                </td>
            </tr>
        </table>
    </div>
</div>
    
<ChatWindow
    :toggle="chat_data.show"
    :lobbies="props.chat_data.lobbies"
    :flex="chat_data.flex"
><span></span>
</ChatWindow>
</template>

<style scoped>
.sidebar{
    /* color-contrast is not yet supported, which is a shame */
}
@media all and (max-aspect-ratio:12/10){
    .sidebar{
        display: none;
    }
}
.button{
    background-color: var(--button-color);
    color: var(--button-text-color);
    border: 2px solid var(--button-border-color);
}
.top {
    position:absolute;
    top: -10px;
    left: 50%;
    transform: translateY(-100%) translateX(-50%); 
}
.tooltip:after {
    content: "";
    position: absolute;
    border: 10px solid var(--case-color);
    pointer-events: none;
}
.tooltip.top:after {
    border-color: var(--case-color) transparent transparent transparent;
    left:calc(50% - 10px);
    top:100%;
}

</style>

