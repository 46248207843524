<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon, SparklesIcon} from '@heroicons/vue/24/solid'

import Tooltip from './Tooltip.vue'
import ButtonLoad from "./ButtonLoad.vue"

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
    { 
        "show" : {
            type : Boolean,
            default: false,
        },
        "match_id" : {
            type: String,
            default: null,
        }
    }
);

var update_wait_timer_id = null;

const extra_data = reactive({
    counter: 0,
    show:false,
    state:"idle",
});

onMounted(() =>{ 
    matchStore.setup();

    extra_data.counter = 0;

    setTimeout(() => update_wait_time(), 0);

    update_wait_timer_id = setInterval(() => {
        update_wait_time();
    }, 1000);
});

onUnmounted(() =>{ 
    extra_data.counter = 0;
    clearInterval(update_wait_timer_id);
});

watch(props, async (newProps, oldProps) => {
    if(newProps.show){
        extra_data.counter = 60;   
        counter_interval_id = setInterval(() => {
            if(extra_data.counter == 0){
                cancel_join_request();
                clearInterval(counter_interval_id);
            }else{
                extra_data.counter--;
            }
        }, 1000);
    } 
});

function update_wait_time(){
    for(const match_type of matchStore.match_types){
        if(matchStore.joinrequests[match_type] == null){
            continue;
        }
        for(const joinrequest of matchStore.joinrequests[match_type]){
            var wait_time = joinrequest.time_to_fill;
            wait_time -= Math.floor((Date.now() - joinrequest.create_time*1000)/1000);
            
            wait_time = Math.max(0, wait_time);
            if(wait_time == 0){
                matchStore.remove_joinrequest(joinrequest.match_id);
            }

            const seconds = Math.floor(wait_time % 60);
            
            const wait_string = `${Math.floor(wait_time / 60)}:${String(seconds).padStart(2, "0")}`;
            joinrequest.wait_time = wait_string;
        }
    }
}

</script>
<template>
    <div v-if="matchStore.youngest_match != null">
        <span class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2 bg-accent-3-color text-light-color">
            <span> Active Match: </span>
            <span v-if="matchStore.youngest_match.match_length == 0" class="flex gap-x-0 items-center">
                {{ matchStore.youngest_match.stake }}<SparklesIcon class="h-6 w-6" />
            </span> 
            <span v-else>
                {{ matchStore.youngest_match.match_length }}pt
            </span> 
            <span class="tabular-nums">{{ matchStore.youngest_match.wait_time }}</span>
            <Tooltip :text="'Cancel this match'" :direction="'bottom'">
                <span class="sr-only">
                    Cancel
                </span>
                <XMarkIcon 
                    class="w-6 h-6 cursor-pointer"
                    @click="matchStore.remove_joinrequest(matchStore.youngest_match.match_id)"
                />
            </Tooltip>
        </span>
    </div>

    <Teleport to="body">
    <TransitionRoot as="template" 
        :show="matchStore.get_joins != null && matchStore.get_joins.length > 0">
    <Dialog as="div" class="relative z-10" @close="">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-main-2-color px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div class="flex flex-col gap-y-3">
            <h1 class="text-center">Opponent wants to join the match</h1>
            <div class="flex justify-center items-center gap-x-4" 
                 v-if="matchStore.get_joins != null && matchStore.get_joins.length > 0"
                :set="join = matchStore.get_joins[0]"
            >
                <span class="text-2xl font-semibold"> {{ join.username }} </span>
                <span class="text-xl font-thin">({{ join.rating.toFixed(0) }})</span>
            </div>
            <!--
            <h1 class="mx-auto text-4xl font-semibold text-center my-4">
                {{ join_request.wait_time }}
            </h1>
            -->
            <div>
                <ButtonLoad class="btn btn-blue mt-2 w-full" 
                    @click="extra_data.state='accepting'; matchStore.join_accept(join.match_id, join.user_id)"
                            :loading="extra_data.state == 'accepting'"
                >
                    Accept
                </ButtonLoad>
                <button class="btn btn-red mt-2 w-full" 
                    @click="matchStore.join_reject(join.match_id, join.user_id)"
                >
                    Reject 
                </button>
            </div> 
            </div> 
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    </Teleport>
</template>

<style scoped>
</style>

