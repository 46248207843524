<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    posts: [],
});


onMounted(() => {
    get_posts();
});

async function get_posts(){
    var url = app_server + `/blog/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
        },
    });
    
    const blog_data = await response.json();
    if(blog_data.status == "success"){
        extra_data.posts = blog_data.posts;
    }
    
    console.log(blog_data);
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12 tracking-wide">OpenGammon Blog</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color shadow rounded py-4 px-6 group relative transition-all" 
            v-for="post in extra_data.posts"
        >
            <div class="grow">
                <router-link :to="{name: 'blog-article', params:{slug: post.slug}}">
                    <h2 class="text-3xl text-center tracking-wide font-medium mb-4 group-hover:text-accent-3-color">{{ post.title }}</h2>
                </router-link>
                <div class="flex gap-x-4 mt-3 opacity-60 group-hover:opacity-100">
                    <div class="flex gap-x-1 items-center">
                        <UserCircleIcon class="w-4 h-4" />
                        <span class="text-right">{{ post.author.username }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(post.update_time*1000).toDateString() }}</span>
                    </div>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ post.summary }}
                </div>
            </div>
            <div class="absolute w-full flex justify-center bottom-4 opacity-0 group-hover:opacity-100 transition-opacity delay-500">
                <router-link :to="{name: 'blog-article', params:{slug: post.slug}}">
                    <button class="btn btn-blue h-10 w-32">Read More</button>
                </router-link>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>

